* {
  outline: none !important;
  box-sizing: border-box;
}
@font-face {
  font-family: "Adobe Clean";
  src: url("../fonts/AdobeClean-Bold.woff2") format("woff2"),
    url("../fonts/AdobeClean-Bold.woff") format("woff"),
    url("../fonts/AdobeClean-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Adobe Clean";
  src: url("../fonts/AdobeClean-Regular.woff2") format("woff2"),
    url("../fonts/AdobeClean-Regular.woff") format("woff"),
    url("../fonts/AdobeClean-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Adobe Clean";
  src: url("../fonts/AdobeClean-SemiCn.woff2") format("woff2"),
    url("../fonts/AdobeClean-SemiCn.woff") format("woff"),
    url("../fonts/AdobeClean-SemiCn.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

.uk-link,
a {
  color: #8c98a9;
}
.uk-link-toggle:focus .uk-link,
.uk-link-toggle:hover .uk-link,
.uk-link:hover,
a:hover {
  color: #8c98a9;
}
.uk-h1,
.uk-h2,
.uk-h3,
.uk-h4,
.uk-h5,
.uk-h6,
.uk-heading-2xlarge,
.uk-heading-large,
.uk-heading-medium,
.uk-heading-small,
.uk-heading-xlarge,
h1,
h2,
h3,
h4,
h5,
h6,
li,
a,
span {
  font-family: "Adobe Clean";
}
span.spectrum-Button-label_e2d99e {
  font-family: inherit;
  margin-top: 3px;
}
a:hover {
  text-decoration: none;
}
html {
  width: 100%;
  height: 100%;
  background: #ffffff;
}
b,
strong {
  font-weight: bold;
}
a:hover,
a {
  color: #037de1;
}
body {
  position: relative;
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  min-height: auto !important;
  height: 100%;
  counter-reset: section;
}
body::-webkit-scrollbar {
  display: none;
}
body {
  font-family: "Adobe Clean";
  background: #ffffff;
  /* height: 100%; */
  /* min-height: 650px; */
  color: #8e8e8e;
  font-size: 14px;
}
img {
  max-width: 100%;
}
.mdl {
  display: table;
  width: 100%;
  height: 100%;
}
.mdl_inner {
  display: table-cell;
  width: 100%;
  vertical-align: middle;
}
header,
footer,
section {
  width: 100%;
  float: left;
}
/* #root {
  height: 100%;
  float: left;
  width: 100%;
} */
#root {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
}
#root > div {
  background: #fff;
}
.loader {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 2;
}
.loader .logo {
  width: 188px;
  display: block;
  margin: 0 auto;
}
.loader .progress {
  width: 100%;
  max-width: 300px;
  display: block;
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;
}
.login_container {
  height: 100%;
}
.login_page {
  height: 100vh;
  /* background-image: url(../images/login_bg.png); */
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.login_page_inner {
  padding: 0;
  float: left;
  width: 100%;
}
.login_page_inner.ii {
  height: calc(100vh - 60px);
  overflow: auto;
  padding-bottom: 35px;
}
.login_side1 {
  float: left;
  width: 50%;
  height: 100vh;
  position: relative;
  padding-left: 0px;
}
.login_side1_inner {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  float: none;
  width: 100%;
  max-width: 350px !important;
  margin-right: auto;
  margin-left: auto;
}
.login_side1 p {
  font-size: 14px;
  line-height: 17px;
  margin: 0;
  padding: 0;
  color: #222222;
  font-weight: 400;
  margin-top: 12px;
}
.login_side1 h1 {
  font-size: 36px;
  color: #000;
  line-height: 36px;
  margin: 0;
  font-weight: bold;
  letter-spacing: 0px;
  margin-top: 25px;
}
.social_login_btns {
  margin-top: 32px;
}
.social_login_btns button {
  display: inline-block;
  padding: 13px;
  width: 350px;
  border-radius: 25px;
  background-color: #4d79ea;
  color: white;
  border: none;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  margin-bottom: 16px;
}
.social_login_btns .google {
  background-color: #f5f6fa;
  color: #222222;
}
.social_login_btns button span {
  vertical-align: bottom;
}
.login_side2 {
  display: inline-block;
  float: left;
  width: 50%;
  height: 100vh;
  overflow: hidden;
}
.login_side2 img {
  width: 100%;
  max-width: 365px;
}
.login_from_box {
  float: left;
  width: 100%;
  min-height: 300px;
  background-color: #ffffff;
  box-shadow: 0px 8px 75px #00000029;
  border-radius: 16px;
  padding: 40px;
}
.login_from_logo {
  float: none;
  width: 90px;
}
.heading_form {
  float: left;
  width: 100%;
  margin-top: 30px;
  margin-bottom: 15px;
}
.heading_form svg {
  color: #0072bc;
  float: left;
  margin-right: 10px;
  width: 24px;
}
.heading_form h3 {
  float: left;
  margin: 0;
  font-size: 28px;
  line-height: 40px;
  color: #000000;
  font-weight: 400;
}
.fields {
  margin-top: 45px;
}
.login_side1_inner form {
  width: 100%;
  max-width: 350px !important;
}
button#signin {
  float: right;
  border-radius: 8px;
  cursor: pointer;
}
button#forgetReset {
  float: right;
}
button#passwordReset {
  float: right;
}
.spStyle button {
  border-radius: 8px;
  cursor: pointer;
}
.login_side1_inner hr {
  position: relative;
}
.login_side1_inner hr::before {
  position: absolute;
  content: "Or";
  background-color: white;
  width: 22px;
  height: 15px;
  color: #8e8e8e;
  left: 50%;
  transform: translateX(-50%);
  top: -12px;
  text-align: center;
  width: 40px;
}
.copyright {
  padding: 0px 20px 10px 0px;
  background-color: white;
}
.copyright p {
  color: #8e8e8e;
  font-size: 11px;
  margin-bottom: 0px;
  text-align: right;
}
.copyright ul {
  list-style: none;
}
.copyright ul li {
  display: inline-block;
}
.copyright ul li a {
  color: #8e8e8e;
}
.signup .login_side1_inner form {
  max-width: 370px !important;
}
.login_page.signup {
  height: calc(100vh - 47px);
}
.signup .login_side1 {
  float: none;
  height: auto;
  margin: 0 auto;
}
.signup .login_side1_inner {
  position: relative;
  top: 0px;
  float: none;
  transform: none;
  width: 100%;
  max-width: 370px !important;
  margin: 0 auto;
}
.signup .login_side1 h1 {
  margin-top: 0px;
}
.social_signup p {
  font-weight: bolder;
  color: #222222;
  font-size: 14px;
}
.social_signup ul {
  list-style: none;
  padding-left: 0px;
}
.social_signup ul li {
  display: inline-block;
}
.social_signup ul li button {
  background-color: transparent;
  border: none;
}
.social_signup .fields {
  margin-top: 0;
}
.social_signup input {
  border-color: #e4e4e4 !important;
}
.fields.select_box {
  margin-top: 15px;
}
.fields.select_box .uk-select {
  width: 80%;
  height: 36px;
  border-left: none;
  border-right: none;
  border-top: none;
  border-color: #e4e4e4;
}
.fields.select_box.ii .uk-select {
  width: 100%;
  margin-top: 10px;
}
.fields.select_box label {
  color: #8e8e8e;
  font-size: 12px;
  font-family: "Adobe Clean";
}
.form_action_btn p {
  font-size: 14px;
  color: #222222;
  font-weight: 400;
  margin-top: 20px;
}
.signup_logo {
  padding-top: 15px;
}
#header {
  position: relative;
  z-index: 2;
  padding-top: 10px;
  padding-bottom: 6px;
  background-color: #000f3c;
  height: 63px;
}
.nav-links {
  list-style: none;
  margin-top: 5px;
  padding: 0;
  margin-bottom: 0;
}
.nav-links li {
  display: inline-block;
  padding: 10px 16px 8px 16px;
}
.nav-links li a {
  font-size: 14px;
  color: white;
}
.nav-links li a:hover {
  text-decoration: none;
}
.toplogo {
  display: table;
  width: 100%;
  padding-top: 5px;
}
.toplogo .mdl_inner {
  display: table-cell;
  vertical-align: middle;
}
.top_right_action_buttons {
  list-style: none;
  margin-top: 0px;
}
.top_right_action_buttons li {
  display: inline-block;
}
.top_right_action_buttons li a {
  color: white;
}
.top_right_action_buttons li a svg {
  width: 20px;
}
.top_right_action_buttons li #notification {
  background-color: transparent;
  border: none;
  padding: 0px;
  width: auto;
}
.top_right_action_buttons li {
  margin-right: 15px;
}
.top_right_action_buttons li a span {
  color: white;
}
.top_right_action_buttons li .logedin_profile > button {
  width: 35px;
  height: 35px;
  background-color: white !important;
  border-radius: 100%;
  margin-top: 5px;
}
.menu_logout svg {
  width: 20px;
}
.top_right_action_buttons li .logedin_profile li {
  width: 100%;
}

.page_wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
}
.sidebar {
  width: 70px;
  height: 100%;
  float: left;
  padding-top: 25px;
  background-color: white;
  transition: all 0.2s ease;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 11;
  overflow: hidden;
  overflow-y: scroll;
}
.sidebar:hover {
  width: 235px;
}
.sidebarLogo {
  width: 64px;
  text-align: center;
  float: left;
  margin-bottom: 15px;
}
.collpase_icon a {
  color: white;
  font-size: 20px;
}
.page_wrapper.collapse .sidebar {
  width: 50px;
  overflow-x: hidden;
}
.page_wrapper.collapse .sidebar .SideBarFixed {
  position: relative;
  bottom: 0;
}
.page_wrapper.collapse .page_body_content {
  width: calc(100% - 50px);
}
.left-padding {
  padding-left: 10px;
}
.left-padding-med {
  padding-left: 15px;
}
.collpase_icon {
  padding-top: 10px;
}
.page_wrapper .sidebar ul li a span,
.page_wrapper .sidebar #desk_company_drop_down {
  display: none;
}
.page_wrapper .sidebar:hover ul li a span,
.page_wrapper .sidebar:hover #desk_company_drop_down {
  display: inline-block;
}
.page_wrapper .sidebar .Sidebar_dropdown {
  float: left;
  padding-left: 14px;
}
.page_wrapper .sidebar .selctWhatsImg {
  height: 32px;
}
.Sidebar {
  height: calc(100% - 5px);
}
.Sidebar_inner {
  height: 100%;
}
.Sidebar_inner > ul {
  position: relative;
  padding-bottom: 300px;
  margin: 0;
}
.SideBarFixed {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 20px;
  border-top: 1px solid #e4e4e4;
  max-width: 64px;
  background-color: #fff;
  padding: 0;
}
.sidebar:hover .SideBarFixed {
  max-width: 229px;
}
.sidebar ul.sidebarUlFixed {
  left: 0;
  bottom: 0;
  margin: 0;
  background: #fff;
  position: fixed;
  width: 68px;
  padding-bottom: 0;
}
.sidebar:hover ul.sidebarUlFixed {
  width: 233px;
}
.sidebar ul {
  list-style: none;
  padding-left: 0;
  padding-bottom: 130px;
}
.sidebar ul li {
  display: block;
  width: 229px;
  overflow: hidden;
  margin-top: 5px;
  transition: all 0.2s ease;
}
.sidebar ul li a {
  display: block;
  width: 100%;
  padding: 5px 10px 5px 20px;
}
.sidebar ul li a:hover {
  background-color: #f5f5f5;
  border-radius: 4px;
}
.sidebar ul li a:hover span,
.sidebar ul li a:hover svg {
  color: #007ad0;
  fill: #007ad0;
}
.sidebar ul li a svg {
  fill: #222222;
  width: 20px;
}
.sidebar ul li a span {
  vertical-align: top;
  font-weight: 500;
  margin-left: 8px;
  font-size: 16px;
  line-height: 21px;
  color: #222222;
}

.sidebar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.sidebar::-webkit-scrollbar {
  width: 2px;
  background-color: #f5f5f5;
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: #5f5f5f;
}

.main_content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.main_content::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
.main_content::-webkit-scrollbar-thumb {
  background-color: #5f5f5f;
}

.page_body_content::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.page_body_content::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
.page_body_content::-webkit-scrollbar-thumb {
  background-color: #5f5f5f;
}

.contacts_list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.contacts_list::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
.contacts_list::-webkit-scrollbar-thumb {
  background-color: #5f5f5f;
}

.page_body_content {
  width: 100%;
  height: 100vh;
  overflow: auto;
  float: left;
  padding: 0px 25px 0px 105px;
  background: #f5f5f5;
}
.page_body_content.workspace {
  overflow-y: scroll;
}
.stats_container .head h2 {
  color: #8e8e8e;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 10px;
  font-weight: bold;
}
.stats_container .uk-grid > div {
  margin-right: 10px;
  cursor: pointer;
}
.stats_container .uk-grid > div:last-child {
  margin-right: 0px;
}
.stats_container .box h6,
.stats_container .box span {
  display: block;
  width: 100%;
}
.stats_container .box {
  display: inline-block;
  width: 100%;
  min-height: 76px;
  padding: 12px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  background-color: white;
}
.stats_container .box .icon .info {
  position: absolute;
  bottom: 0px;
  margin-left: 2px;
  color: #4b4b4b;
  width: 17px;
  right: 0;
}
.stats_container .box .icon .info svg {
  width: 17px;
}
.stats_container .box.active {
  border: 2px solid #32d74b;
  background-color: #32d74b0d;
}
.stats_container .box .text {
  width: calc(100% - 20px);
  display: inline-block;
}
.stats_container .box h6 {
  letter-spacing: 0px;
  color: #4b4b4b;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 1px;
  font-weight: 600;
}
.stats_container .box span {
  font-size: 14px;
  color: #8e8e8e;
  line-height: 21px;
  font-weight: 500;
}
.stats_container .box .icon {
  position: relative;
  display: inline-block;
  vertical-align: top;
  font-weight: 600;
  color: #4b4b4b;
  width: 20px;
}
.stats_container .box .icon svg {
  width: 100%;
}
.graphs_container {
  margin-top: 30px;
}
.graphs_container .head h5 {
  color: #4b4b4b;
  font-size: 14px;
  line-height: 28px;
  font-weight: bold;
}
.tableSec {
  display: inline-block;
  width: 100%;
  margin-top: 20px;
}
.uk-padding-remove-horizontal .head {
  padding: 0 25px 15px 35px;
}
.drpIconBox .uk-dropdown {
  padding: 0;
  box-shadow: 0px 1px 4px #2d2d2d26;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}
.drpIconBox .uk-dropdown ul li a {
  display: block;
  padding: 6px 12px;
  line-height: 20px;
  font-size: 14px;
  color: #4b4b4b;
  text-transform: none;
}
.drpIconBox .uk-dropdown ul li:hover a {
  background-color: #f5f5f5;
}
.iconTble {
  margin-left: 5px;
  width: 16px;
  height: 16px;
  border: none;
  padding: 0;
  color: #8e8e8e;
  background-color: #fff;
}
.drpIconBox:hover .iconTble {
  background-color: #e4e4e4;
}
.tableSec .uk-table th {
  font-size: 10px;
  color: #8e8e8e;
}
.drpDownBtnBox > a {
  font-size: 10px;
  color: #8e8e8e;
}
.drpDownBtnBox .uk-dropdown {
  padding: 0;
  box-shadow: 0px 1px 4px #2d2d2d26;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}
.drpDownBtnBox .uk-dropdown ul li button {
  display: block;
  padding: 6px 12px;
  line-height: 20px;
  font-size: 14px;
  color: #4b4b4b;
  border: none;
  background-color: #fff;
  cursor: pointer;
  text-transform: none;
  width: 100%;
}
.drpDwnList {
  display: inline-block;
  width: 100%;
}
.drpDwnList img {
  float: left;
  width: 22px;
  border-radius: 100%;
}
.drpDwnList svg {
  float: right;
  margin-top: 3px;
  color: #007ad0;
  display: none;
}
.drpDownBtnBox .uk-dropdown ul li.active .drpDwnList svg {
  display: block;
}
.drpDwnList span {
  float: left;
  line-height: 25px;
  width: calc(100% - 40px);
  text-align: left;
  padding-left: 8px;
  height: 22px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.drpIconBox > a {
  font-size: 10px;
  color: #8e8e8e;
}

.star {
  position: relative;
  visibility: hidden;
  font-size: 24px;
  cursor: pointer;
  line-height: 20px;
  margin-left: 10px;
}
.star:before {
  content: "\2606";
  position: absolute;
  visibility: visible;
  color: #8e8e8e;
}
.star:checked:before {
  content: "\2605";
  position: absolute;
  color: #ff9f0a;
}
.avatarIcon {
  width: 26px;
  height: 26px;
  overflow: hidden;
  border-radius: 100%;
}
.ctmBtn {
  display: inline-block;
  border-radius: 15px;
  padding: 4px 8px;
  border: solid 1px #8e8e8e;
  color: #8e8e8e;
  cursor: pointer;
}
.ctmBtn:hover {
  background-color: #333;
  color: #fff;
  border-color: #333;
}
.ctmBtn.infoBtn {
  background-color: #ff9f0a0d;
  border-color: #ff9f0a;
  color: #ff9f0a;
}
.ctmBtn.infoBtn:hover {
  background-color: #ff9f0a;
  border-color: #ff9f0a;
  color: #fff;
}
.ctmBtn.succesBtn {
  background-color: #32d74b0d;
  border-color: #32d74b;
  color: #32d74b;
}
.ctmBtn.succesBtn:hover {
  background-color: #32d74b;
  border-color: #32d74b;
  color: #fff;
}
.ctmBtn.dangreBtn {
  background-color: #ff453a0d;
  border-color: #c9252d;
  color: #c9252d;
}
.ctmBtn.dangreBtn:hover {
  background-color: #c9252d;
  border-color: #c9252d;
  color: #fff;
}
.ctmBtn.BlueBtn {
  background-color: #007ad00d;
  border-color: #007ad0;
  color: #007ad0;
}
.ctmBtn.BlueBtn:hover {
  background-color: #007ad0;
  border-color: #007ad0;
  color: #fff;
}

.tabsBtns {
  float: left;
  margin: 0;
  padding: 0;
  list-style: none;
}
.chat_container {
  background-color: white;
  height: 100vh;
}
.chat_container .head {
  padding: 20px 25px 20px 25px;
  border-bottom: solid 3px #f5f5f5;
}
.page_wrapper .head h3 {
  font-size: 18px;
  color: #000000;
  font-weight: bold;
  margin-top: 10px;
}
.page_wrapper .head h3 span {
  font-weight: 400;
}
.page_wrapper .head .search_field input {
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  background-color: transparent;
  width: 100%;
}
.page_wrapper .head a {
  color: #8e8e8e;
  font-size: 15px;
  display: inline-block;
  margin-top: 10px;
  line-height: 19px;
  font-weight: 400;
}
.page_wrapper .head a svg {
  width: 19px;
}
.page_wrapper .head a span {
  margin-left: 5px;
}
.page_wrapper .head a:hover {
  color: #007ad0;
}
.user_selection button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.user_selection .icon {
  display: inline-block;
  width: 26px;
  height: 26px;
  background-color: black;
  border-radius: 100%;
  margin-right: 8px;
  vertical-align: middle;
}
.user_selection span {
  vertical-align: middle;
  display: inline-block;
  padding-top: 6px;
  color: #4b4b4b;
  margin-right: 4px;
  font-size: 14px;
}
.user_selection .uk-icon {
  padding-top: 5px;
}
.user_selection span.name {
  width: calc(100% - 60px);
  font-size: 14px;
}
.user_selection .uk-dropdown {
  width: 100%;
  max-width: 190px;
  padding: 11px 0px 8px 0px;
  box-shadow: 0px 1px 4px #2d2d2d26;
  border: 1px solid #e4e4e4;
}
.user_selection .uk-dropdown ul {
  list-style: none;
  padding-left: 0px;
}
.user_selection .uk-dropdown ul li {
  margin: 5px 0px;
  padding: 2px 0px;
}
.user_selection .uk-dropdown ul li button {
  width: 100%;
  display: block;
  text-align: left;
  padding: 3px 8px 3px 8px;
}
.user_selection .uk-dropdown ul li button span.uk-icon {
  display: none;
}
.user_selection .uk-dropdown ul li button.active span.uk-icon {
  display: inline-block;
  color: #0a84ff;
}
.user_selection .uk-dropdown ul li button.active {
  border-left: 2px solid #0a84ff;
}
.user_selection .uk-dropdown ul li:hover {
  background-color: #f5f5f5;
}
.seen_status button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #007ad0;
  font-size: 14px;
}
.seen_status {
  margin-top: 4px;
  float: left;
}
.seen_status button span {
  vertical-align: middle;
}
.RefreshMsg button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  color: #007ad0;
  font-size: 14px;
}
.RefreshMsg {
  margin-top: 4px;
  float: left;
  margin-right: 10px;
}
.RefreshMsg button span {
  vertical-align: middle;
  margin-right: 5px;
}
.chat_box {
  height: calc(100vh - 160px);
  border-right: 1px solid #e4e4e4;
  position: relative;
  background: #e4e4e4;
}
.chat_box_head {
  height: 53px;
  padding: 11px 16px 8px 16px;
  border-top: 1px solid #e4e4e4;
  border-bottom: 1px solid #e4e4e4;
  background: #fff;
}
.chat_date p {
  color: #000000;
  font-size: 11px;
  text-align: center;
  padding: 10px 10px;
  margin-bottom: 0px;
}
.messages_box {
  height: calc(100vh - 350px);
  padding-bottom: 30px;
  padding-left: 16px;
  padding-right: 16px;
  overflow-y: auto;
}
.messages_box::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.messages_box::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
.messages_box::-webkit-scrollbar-thumb {
  background-color: #5f5f5f;
}
.messages_box ul {
  padding-left: 0px;
  margin-bottom: 0px;
}
.messages_box ul li {
  width: 100%;
  display: inline-block;
  margin: 13px 0px;
}
.messages_box ul li:first-child {
  margin-top: 0px;
}
.messages_box ul li:last-child {
  margin-bottom: 0px;
}
.message .picture {
  vertical-align: middle;
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  overflow: hidden;
}
.message .picture img {
  width: 100%;
}
.message .text {
  min-width: 60px;
  padding: 11px 14px 20px 14px;
  vertical-align: middle;
  display: inline-block;
  background-color: #ffffff;
  border-radius: 19px;
  margin-left: 16px;
  color: black;
  font-size: 14px;
  max-width: calc(100% - 180px);
  position: relative;
}
.message .text .dateSpan {
  position: absolute;
  right: 12px;
  bottom: 4px;
  font-size: 10px;
  line-height: 10px;
  margin: 0;
}
.message .text span:first-child {
  vertical-align: middle;
  margin-right: 5px;
  word-wrap: break-word;
}
.messages_box li.support .message .text,
.messages_box li.support .message .picture {
  float: right;
}
.messages_box li.support .message .text {
  margin-left: 0px;
  margin-right: 16px;
}
.prompt_msg p {
  width: 90%;
  padding-top: 8px;
  margin: 0 auto;
  border-top: 1px dashed #e4e4e4;
}
.prompt_msg p {
  margin-bottom: 0px;
}
.suggested_msgs {
  padding: 1px 12px;
  margin-bottom: 15px;
}
.suggested_msgs ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
  position: relative;
  z-index: 1;
}
.suggested_msgs ul li {
  display: inline-block;
  margin: 8px 4px;
}
.suggested_msgs ul li button {
  border-radius: 14px;
  border: 1px solid #007ad0;
  background-color: white;
  font-size: 14px;
  color: #000000;
  padding: 5px 10px;
  cursor: pointer;
}
.type_box {
  position: absolute;
  bottom: 0px;
  width: 100%;
  background-color: #fafafa;
}
.type_box .uk-subnav {
  margin-bottom: 0px;
}
.type_box textarea {
  width: 100%;
  height: 100%;
  min-height: 60px;
  background-color: transparent;
  border: none;
  color: #8e8e8e;
  font-size: 14px;
}
.type_box .uk-subnav {
  padding: 1px 1px;
  border-bottom: 1px solid #e4e4e4;
  margin-left: 0px;
}
.type_box .uk-subnav li a {
  position: relative;
  font-size: 16px;
  font-weight: bold;
  color: #8e8e8e;
  text-transform: capitalize;
}
.type_box .uk-subnav li.uk-active a {
  color: #007ad0;
}
.type_box .uk-subnav li.uk-active a::after {
  position: absolute;
  content: "";
  border-bottom: 1px solid #007ad0;
  color: #007ad0;
  background-color: #007ad0;
  width: 100%;
  height: 1px;
  bottom: -3px;
}
.type_box .uk-switcher li {
  background-color: #fafafa;
  padding: 10px 16px 0px 16px;
}
.type_box .uk-switcher li:last-child {
  background-color: #fafafa;
}
.placeholder {
  width: 100%;
}
.submit_bar {
  width: 100%;
  position: absolute;
  background-color: transparent;
  bottom: 6px;
  padding: 1px 16px;
}
.attacments {
  display: inline-block;
  width: 100%;
}
.attacments ul {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
}
.attacments ul li {
  display: inline-block;
}
.attacments ul li button {
  background-color: transparent;
  color: #007ad0;
  border: none;
  cursor: pointer;
}
.attacments ul li button svg {
  width: 24px;
}
.action_btns button {
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid #007ad0;
  color: #007ad0;
  font-size: 15px;
  font-weight: bold;
  padding: 6px 7px;
  float: right;
}
.action_btns button.fill {
  background-color: #007ad0;
  color: white;
  margin-right: 10px;
}
.profile_details {
  /* height: calc(100vh - 120px); */
}
.profile_name h4 {
  font-size: 16px;
  color: #4b4b4b;
  margin-bottom: 0px;
  overflow: hidden;
  max-width: 30ch;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.ModalStyleBtns.ii button {
  float: none;
}
button {
  cursor: pointer;
}
.profile_action button {
  background-color: transparent;
  border: none;
  color: #4b4b4b;
}
.profile_action button circle {
  fill: #4b4b4b;
}
.profile_action button:hover {
  color: #007ad0;
}
.profile_action .uk-dropdown {
  min-width: 160px;
  padding: 10px 0px;
  box-shadow: 0px 1px 4px #2d2d2d26;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}
.profile_action .action_menu {
  padding-left: 0px;
  list-style: none;
  margin-top: 0px;
  margin-bottom: 0px;
}
.profile_action hr {
  margin-top: 10px;
  margin-bottom: 10px;
}
.profile_action .action_menu li {
  /* margin-bottom: 14px; */
}
.profile_action .action_menu li:last-child {
  margin-bottom: 0px;
}
.profile_action .action_menu li button,
.profile_action .action_menu li a {
  color: #4b4b4b;
  font-size: 14px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  padding: 7px 12px;
  display: block;
}
.profile_action .action_menu li button:hover,
.profile_action .action_menu li a:hover {
  background-color: #f5f5f5;
  border-radius: 4px;
}
.profile_action .action_menu li button span.icon,
.profile_action .action_menu li a span.icon {
  margin-right: 8px;
  vertical-align: middle;
}
.profile_action .action_menu li button span,
.profile_action .action_menu li a span {
  vertical-align: unset;
  display: inline-block;
  line-height: 17px;
  color: #4b4b4b;
}
.profile_action .action_menu li.delete button {
  color: #c9252d;
}
.mdl {
  display: table;
  width: 100%;
  height: 100%;
}
.mdl_inner {
  display: table-cell;
  vertical-align: middle;
}
.main_content {
  background-color: #fff;
  padding: 24px 0px 0px;
  overflow-y: auto;
  height: calc(100vh - 144px);
}
.main_content .profile_pic {
  width: 80px;
  height: 80px;
  border: 1px solid #e4e4e4;
  border-radius: 16px;
  margin-left: 14px;
  overflow: hidden;
  float: left;
}
.main_content .profile_pic img {
  width: 100%;
}
.contact_details {
  margin-top: 14px;
  padding: 0px 14px;
  float: left;
  width: 100%;
}
.contact_details ul {
  padding-left: 0px;
  list-style: none;
}
.contact_details ul li {
  font-size: 14px;
  padding: 4px 0px;
  color: #4b4b4b;
}
.contact_details ul li button {
  background-color: transparent;
  border: none;
  color: #007ad0;
  font-size: 14px;
  padding: 0px;
}
.contact_details ul li button span {
  vertical-align: baseline;
}
.contact_details ul li button span:first-child {
  vertical-align: middle;
}
.contact_details ul li button span svg {
  width: 17px;
}
.pause_automation {
  padding: 14px 14px 14px 14px;
  border-top: 1px solid #e4e4e4;
  display: inline-block;
  width: 100%;
}
.pause_automation .head {
  padding: 0px;
}
.pause_automation .head h4 {
  font-size: 14px;
  color: #4b4b4b;
  font-weight: 500;
}
.pause_automation .head h4 span {
  vertical-align: middle;
}
.pause_automation .head .time {
  color: #007ad0;
  background-color: transparent;
  border: none;
}
.pause_automation .head .time span {
  vertical-align: text-bottom;
}
.pause_automation .button button {
  width: 100%;
  color: #007ad0;
  background-color: white;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
  font-size: 14px;
  padding: 8px;
  margin-top: 5px;
}
.awsome {
  background-image: url(/public/images/auto_awesome_black_24dp.svg);
  width: 17px;
  height: 17px;
  background-size: cover;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle;
}
.tags {
  margin-top: 10px;
}
.tags button {
  background-color: #f9fcff;
  border: 0.5px solid #007ad0;
  border-radius: 14px;
  color: #007ad0;
  font-size: 11px;
  margin: 2px;
  padding: 3px 10px 2px 10px;
}
.tags button.time {
  border: none;
  background: transparent;
  padding-left: 0px;
  padding-right: 0px;
  font-size: 12px;
}
.tags button span {
  vertical-align: middle;
}
.select_all {
  display: inline-block;
  vertical-align: middle;
  /* margin-right: 5px;
  padding: 5px 7px 6px 7px; */
  margin-right: 0px;
  padding: 7px 5px;
}
.select_all:hover {
  background-color: #e4e4e4;
  border-radius: 4px;
}
.select_all input {
  border: 2px solid #8e8e8e;
  background-color: #fafafa;
  border-radius: 2px;
}
.select_all:hover input {
  border-color: #4b4b4b;
}
.select_all:hover input:checked {
  border: none;
}
.chat_heads_list {
  border-right: 1px solid #e4e4e4;
}
.chat_heads_list .user_selection button {
  font-size: 10px;
  font-weight: 500;
  text-transform: uppercase;
  color: #4b4b4b;
  border-radius: 4px;
  padding: 5px 5px 5px 10px;
}
.chat_heads_list .user_selection button:hover {
  background-color: #e4e4e4;
}
.chat_heads_list .user_selection button span {
  margin-right: 0px;
}
.chat_heads_list .user_selection button span.uk-icon {
  padding-top: 2px;
}
.chat_heads_list .user_selection .uk-dropdown ul li button.active {
  border: none;
}
.chat_heads_list .user_selection span.name {
  width: calc(100% - 20px);
}
.chat_heads_list .user_selection .uk-dropdown button {
  text-transform: capitalize;
}
/* .contacts_list{
  display: inline-block;
  width: 100%;
  overflow-y: auto;
} */
.contacts_list {
  display: inline-block;
  width: 100%;
  overflow-y: scroll;
  height: calc(100vh - 144px);
}
.contacts_list ul {
  padding-left: 0px;
  margin-bottom: 0px;
}
.contacts_list ul li {
  padding: 15px;
  border-bottom: 1px solid #e4e4e4;
}
.contacts_list ul li:hover,
.contacts_list ul li:active {
  background-color: #e8fdff4d;
}
.contacts_list ul li.active {
  background-color: #e8fdff4d;
}

#Unsubscribe .ModalStyleBtns .confirm {
  margin: 0;
}
/* .contacts_list .chat_tab .chat_select{
  display: inline-block;
  text-align: center;
  padding: 1px 10px;
  border-radius: 30px; 
} */

.contacts_list .chat_tab .chat_select {
  display: inline-block;
  text-align: center;
  padding: 5px 7px 5px 7px;
  border-radius: 30px;
}

.chat_select .checkbox {
  margin-right: 0px;
}

.chat_tab.uk-grid.uk-grid-small {
  justify-content: center;
  align-items: center;
}
.select_all .uk-checkbox:checked {
  margin-right: 0px !important;
}
.user_list .uk-width-auto.uk-flex-first.uk-first-column {
  justify-content: center;
  align-items: center;
  display: flex;
  padding-left: 5px;
}
.chat_select .uk-checkbox:checked,
.uk-checkbox:indeterminate {
  margin-right: 0px !important;
}

.user_list .uk-width-expand {
  padding-left: 10px;
}

.type_box textarea {
  resize: none;
}
.contacts_list .chat_tab .chat_select label {
  padding: 0px;
}
.contacts_list .chat_tab .chat_select input {
  display: inline-block;
  margin-top: 1px;
  border-radius: 2px;
  border-width: 2px;
}
.contacts_list .chat_tab .chat_select:hover {
  background-color: #0000000d;
}
.contacts_list .chat_tab .chat_select:hover input,
.contacts_list .chat_tab .chat_select input:focus {
  border: 2px solid #4b4b4b;
}
.contacts_list .chat_tab .chat_select input:checked {
  border: none;
}
.contacts_list .chat_tab p {
  margin-top: 0px;
  margin-bottom: 0px;
  color: #4b4b4b;
  font-size: 12px;
  line-height: 15px;
  margin-top: 5px;
  word-break: break-all;
  overflow: hidden;
  max-width: 75ch;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contacts_list .chat_tab p:first-child {
  color: #000000;
  font-size: 14px;
  font-weight: 500;
}

.contacts_list .chat_tab .pp {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-color: black;
  border-radius: 100%;
  position: relative;
}
.contacts_list .chat_tab .time {
  height: 15px;
  line-height: 15px;
}
.timeLeft {
  position: absolute;
  right: 0;
  top: 0;
}
.newTime {
  position: absolute;
  right: 0;
  bottom: 0;
}
.assignedunassigned {
  font-weight: 600;
  margin: 0px;
  display: inline-block;
  font-size: 12px;
  color: #8e8e8e;
  line-height: 15px;
  float: left;
  margin-left: -10px;
}
.red .left {
  color: red !important;
}
.contacts_list .chat_tab .time p {
  font-weight: 600;
  margin: 0px;
  display: inline-block;
  font-size: 12px;
  color: #8e8e8e;
}
.contacts_list .chat_tab .time .countdown p {
  color: #007ad0;
}
.contacts_list .chat_tab .time span.left {
  display: inline-block;
  color: #007ad0;
  font-size: 12px;
  margin-left: 2px;
}
.contacts_list .chat_tab .time .countdown {
  position: relative;
  top: -5px;
  display: inline-block;
  margin-left: 5px;
}
.contacts_list .chat_tab .time .countdown span {
  font-size: 12px;
  color: #007ad0;
}
.uk-countdown-hours {
  margin-left: 1px;
}
.assignment_actions {
  width: 220px;
  display: block;
  margin: 0 auto;
}
.assignment_actions ul {
  list-style: none;
  padding-left: 0px;
  list-style: none;
}
.assignment_actions li {
  margin-bottom: 12px;
}
.assignment_actions li button {
  width: 100%;
  border: 1px solid #e4e4e4;
  background-color: white;
  border-radius: 7px;
  color: #007ad0;
  font-size: 14px;
  padding: 11px 0px 10px 0px;
}
.assignment_actions .user_selection span {
  vertical-align: text-bottom;
  padding-top: 0px;
}
.assignment_actions .user_selection .uk-dropdown span {
  vertical-align: inherit;
  padding-top: 0px;
}
.assignment_actions li button span {
  vertical-align: text-bottom;
  color: #007ad0;
  font-size: 14px;
}
.assignment_actions li button span:first-child {
  padding-right: 0px;
}
.assignment_actions li button span:first-child,
.assignment_actions li button span:first-child svg {
  width: 17px;
}
.assignment_actions .user_selection .uk-dropdown ul li button {
  border: none;
  border-radius: 0px;
}
.assignment_actions .user_selection .uk-dropdown ul li button.active {
  border-left: 2px solid #0a84ff;
}
.resolved_content {
  margin-bottom: 40px;
  display: inline-block;
  width: 100%;
  position: relative;
}
.chipBox .css-1s2u09g-control {
  background-color: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
}
.resolved_content .ModalStyleBtns {
  width: auto;
}
.resolved_content p {
  font-size: 14px;
  color: #000000;
  margin-top: 5px;
  margin-bottom: 5px;
}
.resolved_content p.small {
  font-size: 12px;
}
.quickModal .uk-modal-dialog {
  width: 100%;
  max-width: 904px !important;
}
.AlertPOPUP .uk-modal-dialog {
  max-width: 465px !important;
}
.AlertPOPUP .uk-modal-dialog h3 {
  font-size: 18px;
  padding-bottom: 5px;
}
.spectrum-Checkbox-box_4870fc:before {
  background-color: white !important;
}
.spectrum-Checkbox_4870fc .spectrum-Checkbox-checkmark_4870fc,
.spectrum-Checkbox_4870fc .spectrum-Checkbox-partialCheckmark_4870fc {
  color: white !important;
}
.spectrum-Checkbox-label_4870fc {
  font-family: inherit;
  color: #222222 !important;
}
.forgot_password {
  /* text-align: right; */
  padding-top: 4px;
  font-size: 14px;
}
.forgot_password a {
  color: #3566d1 !important;
}

.tabsBtns li {
  float: left;
  margin-right: 20px;
}
.tabsBtns li a {
  float: left;
  padding: 10px 0px;
  color: #8e8e8e;
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  position: relative;
  text-decoration: none;
}
.tabsBtns li.uk-active a {
  color: #1b7ff5;
}
.tabsBtns li a::before {
  position: absolute;
  left: 0;
  bottom: -2px;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #1b7ff5;
  display: none;
}
.tabsBtns li.uk-active a::before {
  display: block;
}
.TabSec {
  float: left;
  width: 100%;
}
.TabsHeadBox {
  float: left;
  width: 100%;
  position: relative;
  padding: 20px 25px 0px 25px;
  border-bottom: solid 3px #f5f5f5;
  background-color: #fff;
}
.searchnFlowBtn {
  position: absolute;
  right: 0;
  top: 15px;
  padding-right: 25px;
}
.searchnFlowBtn .uk-search {
  float: left;
}
.searchnFlowBtn .uk-search-input {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  margin-right: 32px;
  float: left;
  width: 450px;
  color: #8e8e8e;
  font-size: 14px;
  line-height: 18px;
  padding: 8px 15px 4px 15px;
}
.newFlowBtn {
  float: left;
  color: #ffffff;
  font-size: 15px;
  line-height: 20px;
  padding: 5px 15px;
  background-color: #007ad0;
  border: solid 1px #007ad0;
  border-radius: 8px;
  height: 32px;
}
.newFlowBtn.uk-float-right {
  float: right !important;
}
.newFlowBtn:hover {
  color: #007ad0;
  background-color: #cae4f7;
}
.notice {
  font-size: 12px;
  color: #8e8e8e;
  margin-left: 20px;
  margin-top: 5px;
}
.integration {
  background-color: #f5f5f5;
  padding: 42px 25px 25px;
}
.integration h4 {
  font-size: 14px;
  font-weight: bold;
  color: #4b4b4b;
}
.cards .actions button {
  float: right;
  color: #ffffff;
  font-size: 15px;
  line-height: 18px;
  padding: 5px 15px;
  background-color: #007ad0;
  border: solid 1px #007ad0;
  border-radius: 8px;
}
.cards .actions button:hover {
  color: #007ad0;
  background-color: #cae4f7;
}
.cards .actions a {
  font-family: inherit;
  float: right;
  color: #ffffff;
  font-size: 15px;
  line-height: 18px;
  padding: 6px 15px 6px 15px;
  background-color: #007ad0;
  border: solid 1px #007ad0;
  border-radius: 8px;
}
.cards .actions a:hover {
  color: #007ad0;
  background-color: #cae4f7;
}
.cards .uk-card-title {
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}
.cards .uk-card-media-top {
  position: relative;
}
.brand_label {
  position: absolute;
  display: inline-block;
  width: 60px;
  height: 60px;
  left: 24px;
  bottom: -20px;
  border-radius: 10px;
  overflow: hidden;
}
.brand_label img {
  width: 100%;
  height: 100%;
}
.cards .uk-card .uk-card-body {
  padding: 35px 24px 24px 24px;
}
.cards .uk-card .uk-card-body p {
  margin-top: 10px;
}
.tableSec .uk-table p {
  margin: 0 0 10px;
  line-height: 20px;
}
.tableSec .uk-table p:last-child {
  margin: 0;
}
.whatsAppTxt {
  display: inline-block;
  line-height: 20px;
}
.whatsAppTxt img {
  margin-right: 5px;
}
.tableSec .uk-table td > span {
  color: #007ad0;
}
.BroadcastingContainer .stats_container {
  padding: 24px;
}
.stats_container .box span svg {
  width: 14px;
}
.tableSec .uk-table tbody td .uk-dropdown {
  padding: 0;
  box-shadow: 0px 1px 4px #2d2d2d26;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}
.tableSec .uk-table tbody td .uk-dropdown ul li a {
  display: block;
  padding: 6px 12px;
  line-height: 20px;
  font-size: 14px;
  color: #4b4b4b;
  text-transform: none;
}
.tableSec .uk-table tbody td .uk-dropdown ul li:hover a {
  background-color: #f5f5f5;
}
.TeamContainer .searchnFlowBtn {
  float: right;
  position: relative;
  top: 0;
  padding: 0;
  margin-top: 4px;
}
.new-container {
  padding: 20px 25px 0px 20px !important ;
}
.ModalStyle .uk-modal-dialog {
  background-color: #fafafa;
  border-radius: 4px;
}
.ModalStyle .uk-modal-close-default {
  color: #000;
  width: 24px;
  height: 24px;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
}
.ModalStyle label {
  font-size: 12px;
  color: #8e8e8e;
}
.ModalStyle select,
.ModalStyle input {
  border-color: #b1b1b1;
  border-radius: 4px;
}
.ModalStyle .uk-modal-close-default svg {
  width: 100%;
}
.ModalStyle .uk-modal-dialog h3 {
  color: #000000;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: solid 2px #d5d5d5;
}
.ModalStyle .toggle_box p {
  color: #000000;
  font-size: 12px;
}
.msg_box {
  width: 100%;
  background-color: white;
  padding: 16px 14px 14px;
  border-radius: 12px;
  margin-top: 12px;
  position: relative;
  display: inline-block;
}
.msg_box h6 {
  font-size: 16px;
  color: #222222;
  font-weight: 500;
  margin-bottom: 0px;
}
.msg_box.uk-text-center {
  margin-top: 10px;
}
.msg_box.uk-text-center h6 {
  font-weight: bold;
  margin-top: 0px;
}
.msg_box p {
  color: #222222;
  font-size: 14px;
}
.msg_box p.time {
  text-align: right;
  margin-bottom: 0px;
}
.modal_2 a {
  font-size: 15px;
  text-decoration: none;
  color: #007ad0;
  font-weight: 500;
}
.ImportBtn button {
  background-color: transparent;
  font-size: 14px;
  color: #007ad0;
  border: 1px solid #d5d5d5;
  border-radius: 16px;
  padding: 8px 12px;
  margin-right: 25px;
}
.msg_preview {
  float: right;
  display: inline-block;
  width: 100%;
  max-width: 300px;
  background-color: #dad5cb;
  border-radius: 12px;
  padding: 24px;
}
.msg_preview h2 {
  font-size: 16px;
  margin-bottom: 0px;
}
.AddBroadcastSide_i p {
  color: #000000;
  font-size: 14px;
}
.fields_section {
  margin-top: 24px;
}
.fields_section h5 {
  font-size: 16px;
  font-weight: 500;
  color: #222222;
}
.fields_section p {
  color: #8e8e8e;
  font-size: 12px;
}
.fields_section textarea {
  border: 1px solid #d5d5d5;
  width: 100%;
  background-color: white;
  min-height: 128px;
  color: #222222;
  font-size: 14px;
  border-radius: 4px;
  padding: 8px 12px;
}
.emojis {
  background-color: #4b4b4b;
  box-shadow: 0px 1px 4px #00000026;
  border: 1px solid #dddddd;
  border-radius: 4px;
  width: 69px;
  float: right;
  display: inline-block;
  clear: both;
  text-align: center;
  padding: 2px 0px;
}
.emojis button {
  background-color: transparent;
  color: white;
  border: none;
  font-size: 16px;
}
.emojis button svg {
  width: 20px;
}
.action_btns {
}
.AddTeamModal .uk-modal-dialog,
.AddNewContact .uk-modal-dialog {
  width: 470px;
}
.AddNewContact .uk-modal-dialog p {
  color: black;
  font-size: 14px;
  font-weight: bold;
}
.AddBroadcastModal .uk-modal-dialog {
  width: 100%;
  max-width: 904px !important;
}
.integration.inner {
  float: left;
  width: calc(100% - 216px);
  border-left: solid 2px #f5f5f5;
}
.addnum_box {
  width: 100%;
  max-width: 400px;
}
.addnum_box h4 {
  color: #222222;
  font-size: 16px;
}
.addnum_box .fields {
  margin-top: 0;
}
.addnum_box .fields input {
  background-color: #fff !important;
  border-bottom: 1px solid #e4e4e4;
  border-top: none;
  border-left: none;
  border-right: none;
}
.connected {
  margin-top: 40px;
}
.connected h4 {
  color: #222222;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 20px;
}
.connected h5 {
  color: #222222;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 0px;
}
.connected p {
  color: #8e8e8e;
  font-size: 18px;
  margin-top: 0px;
}
.connected .status {
  display: inline-block;
  background-color: #ff9f0a0d;
  border: 1px solid #ff9f0a;
  border-radius: 12px;
  font-size: 12px;
  padding: 3px 10px 2px 10px;
  color: #ff9f0a;
}
.addRowBtn {
  background-color: transparent;
  color: #007ad0;
  font-size: 14px;
  border: none;
  font-weight: 500;
}
.addRowBtn span {
  vertical-align: top;
}
button.disconnect {
  background-color: transparent;
  font-size: 14px;
  color: #007ad0;
  text-decoration: none;
  border: none;
  padding: 0;
}
button.disconnect svg {
  margin-right: 5px;
}
.ModalStyle.disconectAcount .uk-modal-dialog h3 {
  font-size: 18px;
}
.disconectAcount .uk-modal-dialog {
  width: 364px;
}
.disconectAcount .uk-modal-dialog h5 {
  font-weight: bold;
  color: #222222;
  margin-bottom: 0px;
}
.disconectAcount .uk-modal-dialog p {
  font-size: 14px;
  font-weight: bold;
  color: #000000;
}
.disconectAcount .uk-modal-dialog form p {
  color: #8e8e8e;
  margin-top: 0px;
  font-weight: 400;
}
button.confirm {
  float: left;
  font-size: 15px;
  line-height: 18px;
  padding: 5px 15px;
  border: solid 1px #d31510;
  color: #d31510;
  background-color: white;
  border-radius: 8px;
  margin-right: 16px;
}

.disconectAcount .ModalStyleBtns {
  width: auto !important;
  float: right;
  margin-top: 20px;
}
.chipBox {
  display: inline-block;
  width: 100%;
}
.chip {
  float: left;
  display: inline-block;
  padding: 3px 0px;
  background: #fdfdfd !important;
  border: 1px solid #6d6d6d;
  border-radius: 4px !important;
  cursor: pointer;
  margin-right: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  color: black !important;
}
.chip img {
  float: left;
  width: 16px;
  border-radius: 100%;
}
.chip svg {
  float: left;
  margin-top: 0;
  color: #6d6d6d;
}

.chip span {
  float: left;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
  padding-left: 8px;
  padding-right: 8px;
  height: 16px;
  color: #464646;
}
.ModalStyleBtns {
  display: inline-block;
  width: 100%;
}
.AddTeamBtn {
  float: left;
  color: #ffffff;
  font-size: 15px;
  line-height: 18px;
  padding: 5px 15px;
  background-color: #007ad0;
  border: solid 1px #007ad0;
  border-radius: 8px;
  margin-right: 16px;
}
.AddTeamBtn:hover {
  color: #007ad0;
  background-color: #cae4f7;
}
.CnclBtn {
  float: left;
  color: #222222;
  font-size: 15px;
  line-height: 18px;
  padding: 5px 15px;
  background-color: #00000000;
  border: solid 1px #e4e4e4;
  border-radius: 8px;
  margin-right: 16px;
}
.CnclBtn:hover {
  color: #fff;
  background-color: #222222;
}
.CustomInputOption label span {
  font-size: 10px;
  margin-top: 5px;
}
.CustomInputOption a svg {
  margin-top: -40px;
  margin-left: -5px;
}
.date .spectrum-Field_d2db1f {
  margin-right: 20px;
}
.date {
  position: relative;
}
/* .date span svg {
  position: absolute;
  top: 35px;
  right: 85px;
  width: 4%;
  color: #000;
} */
.CustomForm .uk-form-custom {
  display: inline-block;
  position: relative;
  max-width: 100%;
  vertical-align: middle;
  width: 100%;
  border: 1px solid #d5d5d5;
  height: 80px;
  text-align: text;
  cursor: pointer;
}
.CustomForm svg {
  color: #007ad0;
  text-align: center;
  width: 40px;
  margin-top: 15px;
  cursor: pointer;
}
.CustomForm {
  text-align: center;
  cursor: pointer;
}
.CustomForm p {
  text-align: center;
  color: #007ad0;
  margin-top: 0px;
  cursor: pointer;
}
.CustomForm .uk-form-custom input[type="file"] {
  font-size: 10px;
  overflow: none;
}
div#ImportcontactModal .uk-modal-dialog {
  width: 465px;
}
.ModalStyle .CustomForm label {
  float: left;
  margin-bottom: 5px;
}
.mediaformfield p {
  margin-top: 20px;
  margin-bottom: 10px;
}
.mediaformfield p.Hint {
  margin-top: 0px;
}
.mediaformfield h5 {
  margin: 0px 0px 10px;
  color: #8e8e8e;
}
.mediaformfield input[type="file"] {
  cursor: pointer;
}
.mediaformfield label input {
  cursor: pointer;
}
.mediaformfield input.uk-radio {
  border-radius: 20px;
  margin-right: 5px;
}
.mediaformfield .uk-radio:checked,
.uk-checkbox:checked,
.uk-checkbox:indeterminate {
  background-color: #000 !important;
}
.media_msg_box_img {
  width: 100%;
  height: 124px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e4e4e4;
  border-radius: 4px;
}
div#UserField .uk-form-label span svg {
  margin-left: 5px;
  color: #4b4b4b;
  width: 15px;
}
.media_msg_box_img svg {
  width: 80px;
  color: #fff;
}
#UserField .ModalStyleBtns,
#NewBot .ModalStyleBtns,
#AddTagModal .ModalStyleBtns {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
#UserField .ModalStyleBtns .AddTeamBtn,
#NewBot .ModalStyleBtns .AddTeamBtn,
#AddTagModal .ModalStyleBtns .AddTeamBtn {
  margin: 0;
}
.CustomAddBtn .newFlowBtn {
  margin-top: 20%;
}

section.react-spectrum-ContextualHelp-content_d88303 p {
  margin: 0;
}

.CustomAddBtn .newFlowBtn {
  margin-top: 20%;
}

ul.top_right_action_buttons button {
  background-color: transparent;
  border: none;
  color: #fff;
}
.uk-form-custom h5 {
  text-align: left;
  padding: 10px;
}
.CustomForm.CustomFormUpload {
  text-align: left;
}
.CustomForm.CustomFormUpload h5 {
  margin-bottom: 0;
}
.CustomForm.CustomFormUpload svg {
  color: #007ad0;
  text-align: center;
  width: 25px;
  margin-top: 8px;
  cursor: pointer;
}
.CustomForm.CustomFormUpload .uk-form-custom h5 {
  text-align: left;
  padding: 20px 10px 0px;
}
.CustomForm.CustomFormUpload span {
  padding: 0px 15px;
  color: #8e8e8e;
}
/* filter main start */
.Filtermain {
  position: relative;
}
.Filtermain .uk-dropdown-nav li a {
  margin-top: 0px;
}

.Filtermain .uk-inline input {
  width: 100%;
  margin-left: 6px;
  margin-top: 15px;
}
.Filtermain .uk-dropdown {
  padding: 0px;
  background: #fff;
  color: #666;
  box-shadow: 0 5px 12px rgb(0 0 0 / 15%);
  border: 1px solid #dddddd;
  border-radius: 4px;
  left: 0px !important;
  top: 40px !important;
}

.Filtermain button {
  border: none;
  background-color: transparent;
  color: #8e8e8e;
  font-size: 15px;
  margin-top: 10px;
  line-height: 19px;
  font-weight: 400;
  margin-bottom: 5px;
}
div#emailDrop,
div#nameDrop,
div#dateDrop,
div#cityDrop,
div#countryDrop,
div#regionDrop,
div#genderDrop,
div#usernameDrop,
div#phoneDrop {
  padding: 10px;
}
.Filtermain .uk-dropdown-nav button {
  color: #000;
  text-transform: capitalize;
  font-size: 14px;
  padding: 0px 10px;
  width: 100%;
  text-align: left;
}
.customNavDrop input {
  width: 85%;
  float: right;
  margin: 0px auto;
}
.customNavDrop input.uk-radio {
  margin-right: 5px;
  width: 15px;
  float: left;
}
#emailDrop .uk-radio:checked,
#nameDrop .uk-radio:checked,
#dateDrop .uk-radio:checked,
#cityDrop .uk-radio:checked,
#countryDrop .uk-radio:checked,
#regionDrop .uk-radio:checked,
#genderDrop .uk-radio:checked,
#usernameDrop .uk-radio:checked {
  background-color: #000 !important;
  border-color: transparent;
  margin-right: 5px !important;
}
.customNavDrop select {
  width: 84%;
  float: right;
  border: 1px solid #b1b1b1 !important;
  padding: 4px;
}
#emailDrop label,
#nameDrop label {
  color: #000;
}
#emailDrop input.uk-radio,
#nameDrop input.uk-radio {
  margin-right: 5px;
}

#emailDrop input[type="text"] {
  width: 80%;
  display: block;
  /* float: right; */
  margin: 5px auto;
  border-radius: 4px;
  border: 1px solid #0265dc;
  padding: 4px;
}
.page_wrapper .Filtermain button:hover {
  color: #007ad0;
}
.Filtermain li.uk-active {
  background-color: #f5f5f5;
}
/* filter end  */

/* segment start */
.SegmentAction li.hintAlert a {
  color: red !important;
}
.SegmentAction ul hr {
  margin: 0;
}
.SegmentAction ul.uk-nav.uk-dropdown-nav li a svg {
  margin-right: 6px;
}
div#NewSegment .uk-modal-dialog {
  width: 888px;
}
button.deleteBtn {
  background-color: #fff;
  border: 1px solid #b1b1b1;
  padding: 8px 10px;
  border-radius: 4px;
}
div#NewSegment form input,
div#NewSegment form select {
  width: 245px;
  color: #000;
}

button.simpleAddBtn {
  background-color: transparent;
  border: none;
  color: #007ad0;
}
#NewSegment .ModalStyleBtns {
  display: flex;
  justify-content: flex-end;
}
div#SaveUserSegment .uk-modal-dialog {
  width: 600px;
}
div#SaveUserSegment .uk-margin p,
div#SaveUserSegment .uk-margin h5,
div#SaveUserSegment .AddTeamBtn {
  margin: 0;
}
div#SaveUserSegment h4 {
  color: #000000;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: solid 2px #d5d5d5;
}
button.simpleAddBtn svg {
  margin-top: -4px;
}
div#SaveUserSegment .ModalStyleBtns {
  display: flex;
  justify-content: flex-end;
}
/* segment end */

/* template message start */
.customCard .uk-card {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}
.customCard .uk-card-header {
  padding: 20px;
}
.customCard .uk-card-footer {
  display: flex;
  justify-content: flex-end;
  padding: 20px;
}

.customCard .uk-card-footer a.uk-button.uk-button-text {
  background-color: #007ad0;
  border-radius: 8px;
  padding: 5px 12px 3px;
  font-size: 12px;
  color: #fff;
  text-transform: capitalize;
}
.customCard h4 {
  font-size: 16px;
  margin-bottom: 5px;
  margin-top: 15px;
}
.TabFourBroadCast {
  padding: 20px;
}

/* template message end */

.CustomChipBox .chip {
  align-items: center;
  background: transparent;
  border-radius: 4px;
  color: #000;
  display: inline-flex;
  font-size: 13px;
  line-height: 15px;
  margin-bottom: 8px;
  margin-right: 10px;
  padding: 5px 10px 2px;
  border: 1px solid #e4e4e4;
}
.CustomChipBox .searchWrapper {
  border: 1px solid #ccc;
  border-radius: 4px;
  min-height: 22px;
  padding: 10px 4px;
  position: relative;
}
.CustomChipBox img.icon_cancel.closeIcon {
  background-color: #6d6d6d;
  background-size: cover;
}
.CustomChipBox .closeIcon {
  cursor: pointer;
  float: right;
  height: 15px;
  margin-left: 10px;
  width: 15px;
  border: 1px solid;
  margin-bottom: 4px;
}
.uk-button-text::before {
  display: none;
}
/* template message end */

/* contact profile start */

/* .tableSec td button.dataTable {
  background-color: transparent;
  border: none;
} */

.tableSec td button.dataTable {
  background-color: transparent;
  border: none;
  color: #007ad0;
}

#ContactProfile .uk-modal-dialog {
  width: 610px;
}
div#ContactProfile .profile_pic {
  width: 160px;
  height: 160px;
}
#ContactProfile .ProfileLeft {
  border-right: 1px solid #e4e4e4;
  padding-top: 30px;
}
#ContactProfile .main_content {
  padding: 0;
}
div#ContactProfile h3 {
  margin: 0;
}
.CustomProfileBtn button {
  background-color: transparent;
  text-align: left;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  margin-bottom: 8px;
  padding: 8px 20px;
  font-size: 11px;
  color: #007ad0;
}
#ContactProfile .tags button {
  background-color: #f9fcff;
  border: 0.5px solid #007ad0;
  border-radius: 14px;
  color: #007ad0;
  font-size: 11px;
  margin: 4px 2px;
  padding: 3px 10px 2px 10px;
}
#ContactProfile .tags .button {
  background-color: #f9fcff;
  border: 0.5px solid #007ad0;
  border-radius: 14px;
  color: #007ad0;
  font-size: 11px;
  margin: 4px 2px;
  padding: 2px 10px 2px 10px;
  display: inline-block;
}
#ContactProfile .tags .button button {
  width: auto;
  border: none;
  padding: 0px;
  background-color: white;
  margin: 0;
  display: inline-block;
}
#ContactProfile .pause_automation {
  padding: 30px 0px 14px 0px;
  border-top: 1px solid #e4e4e4;
}
#ContactProfile .uk-dropdown.uk-open {
  padding: 0;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}
#ContactProfile .uk-dropdown.uk-open {
  display: block;
  width: 100px;
  max-width: 100px;
  min-width: 170px;
}
#ContactProfile ul.uk-nav.uk-dropdown-nav li {
  padding: 2px 20px;
}

.IframeEmbd {
  width: 100%;
  height: 90vh;
}
.BgImg {
  background: #000;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.moblogo {
  display: none;
  width: 100%;
  max-width: 260px;
}
/* contact profiel end */

.mainauto li button {
  background-color: transparent;
  border: none;
  padding-left: 13px;
}

#msg-dialog.custom-uk-alert-success {
  width: 550px;
  margin: 0px auto 20px;
}

select.customSelect {
  width: 175px;
  margin-left: 5px;
  border: none;
}
.customHeading h2 {
  font-size: 20px;
  color: #4b4b4b;
}

/* .action_btns.customaction_btns {
  position: absolute;
  bottom: 0;
  right: 0;
} */

.Sidebar_inner ul li ul li a span {
  font-size: 14px;
  margin-left: 35px;
  color: #4b4b4b;
}
.text.customPdfBtn a {
  color: #fff;
}

.text.customPdfBtn a svg {
  margin-right: 5px;
}

.text.customPdfBtn {
  background-color: #0a84ff;
}

select.customSelect {
  width: 175px;
  margin-left: 5px;
  border: none;
}
.customHeading h2 {
  font-size: 20px;
  color: #4b4b4b;
  width: 120px;
  float: left;
}
div.all,
div.agent,
div.bot {
  width: 100%;
  float: left;
}

.HomeCustomDrop .uk-dropdown {
  padding: 0;
  z-index: 1;
}
.HomeCustomDrop ul.uk-nav.uk-dropdown-nav {
  border: 1px solid #b1b1b1;
  border-radius: 4px;
  /* max-width: 175px; */
}
.HomeCustomDrop button {
  border: none;
  background-color: transparent;
  font-size: 17px;
  color: #4b4b4b;
}
.HomeCustomDrop ul.uk-nav.uk-dropdown-nav li button {
  width: 100%;
  text-align: left;
  padding: 10px 10px;
}
button#contypes {
  position: relative;
}
button#contypes:after {
  display: block;
  content: "";
  clear: both;
  background-image: url(/public/images/expand_down.svg);
  width: 14px;
  height: 8px;
  position: absolute;
  right: -16px;
  top: 6px;
}
.HomeCustomDrop ul.uk-nav.uk-dropdown-nav li:hover button {
  background-color: #eee;
}
.customHeading.head {
  width: 100%;
  display: block;
}

.toplogo path#Union_31_-_Outline {
  fill: #fff;
}
.toplogo path#Union_31 {
  fill: #fff;
}

.CustomBgAdmin {
  padding: 32px;
  background-color: #f5f5f5;
  display: inline-block;
}

.CustomBgAdmin .integration.inner {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}
.adminContainer .uk-padding-remove-horizontal .head {
  padding: 0 25px 15px 35px;
  border-bottom: 1px solid #e4e4e4;
}

.CustomBgAdmin .integration.inner label.uk-form-label {
  color: #4b4b4b;
  font-size: 14px;
  line-height: 28px;
  /* padding-bottom: 48px; */
}

.CustomBgAdmin .integration.inner .uk-form-controls input,
.CustomBgAdmin .integration.inner .uk-form-controls select {
  background-color: #fafafa;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  margin-bottom: 40px;
}

.CustomBgAdmin .integration.inner .uk-form-controls.customMargin {
  margin-top: 27px;
}

.CustomBgAdmin .integration h4 {
  margin: 0;
}
.CustomBgAdmin .integration p {
  margin: 10px 0px;
}

.CustomBgAdmin button {
  /* border: 1px solid #E4E4E4; */
  border-radius: 8px;
  padding: 10px 20px;
  margin-bottom: 30px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  margin-right: 85px;
  background-color: #007ad0;
  border: solid 1px #007ad0;
}
.CustomBgAdmin button:hover {
  color: #007ad0;
  background-color: #cae4f7;
}
.CustomBgAdmin button.delete {
  color: #ec2d37;
}

/* toggle btn start */

.switch {
  position: relative;
  display: inline-block;
  width: 46px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 1px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}
/* toggle btn end */

.whatsAppTxt.AutoTxt ul {
  list-style: none;
  /* display: inline-block; */
  padding: 0;
}

.whatsAppTxt.AutoTxt ul li {
  float: left;
  border: 1px solid #eee;
  padding: 3px 5px 0px;
  margin: 0px 2px;
  color: #464646;
  border-radius: 4px;
  background-color: #ffff;
  /* line-height: 21px; */
}
.whatsAppTxt.FlowTxt p {
  border: 1px solid #e4e4e4;
  padding: 3px 10px 0px;
  border-radius: 4px;
  background-color: #fff;
}
.whatsAppTxt.AutoTxt li a {
  color: #464646;
}

.AutomationTabelHover tr:hover {
  background-color: #fafafa;
}

.date #broadcast_date,
.date #broadcast_time {
  border: 1px solid #b1b1b1 !important;
  padding: 7px;
  margin-right: 5px;
  color: #666;
}
input#filterDate {
  border: 1px solid #b1b1b1 !important;
  padding: 4px;
  color: #666;
}

.EditAgentMain {
  background-color: #eee;
  height: 100vh;
  padding: 25px;
}
div#image_div img {
  object-fit: fill;
  width: 220px;
  height: 120px;
}

.EditAgentInfo .stats_container {
  background: #fff;
  padding: 25px 20px 0px;
}
.EditAgentInfo .stats_container .uk-grid > div {
  margin-right: 0px;
}
label.CustomNoti {
  /* margin-right: 20px; */
  margin-left: 14px;
}
.CustomBgAdmin.NotificationView .integration.inner {
  height: 100vh;
}
.EditAgentInfo {
  background-color: #fff;
  float: left;
  width: 100%;
  height: 77vh;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}
.EditAgentInfo #EditAgentProfile {
  padding: 20px 25px;
  float: left;
}
.Agent_profile_pic {
  width: 160px;
  height: 160px;
  border: 1px solid #eee;
  border-radius: 16px;
  object-fit: cover;
}
#EditAgentProfile .ProfileLeft .contact_details {
  padding: 0;
}
.contact_details.contact_details_Content p {
  margin: 0px;
}
.tableSec .CustomTableWidth {
  /* width: 1440px;
  height: 75vh; */
}
.timingBox {
  float: right;
  position: relative;
  height: 100%;
  width: 100px;
}
.uk-margin-top {
  margin-top: 23px !important;
}
div#AddNewContact .uk-input:disabled,
div#AddNewContact .uk-select:disabled,
div#AddNewContact .uk-textarea:disabled {
  background-color: #fff;
  color: #999;
  border-color: #b1b1b1;
}
div#AddTagsModal form#tagform {
  margin-top: 20px;
}

.Sidebar_dropdown {
  /* padding: 10px; */
  display: block;
  width: 100%;
  padding: 8px 10px 7px 10px;
}
.Sidebar_dropdown button {
  background-color: #fff;
  border: 1px solid #e4e4e4;
  text-align: left;
  padding: 8px 10px 8px 10px;
  border-radius: 4px;
  width: 43px;
}
.sidebar:hover .Sidebar_dropdown button {
  width: 100%;
}
.Sidebar_dropdown button img {
  float: left;
}
.Sidebar_dropdown button span {
  display: none;
  float: left;
  line-height: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
}
.sidebar:hover .Sidebar_dropdown button span {
  display: block;
}
.Sidebar_dropdown .uk-dropdown {
  padding-left: 0;
  padding-right: 0px;
  padding-top: 5px;
  padding-bottom: 10px;
  left: 10px !important;
}
span.CaretSortDown.uk-float-right svg {
  width: 20px;
  margin-top: -10px;
}
.Sidebar_dropdown .uk-dropdown.uk-open ul li button {
  border: none;
  padding: 5px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
div#profile_details .main_content .profile_pic {
  border: none;
}
.CampaignReports.Filtermain {
  position: relative;
}
.CampaignReports.Filtermain button {
  position: absolute;
  right: 2px;
  top: -40px;
}
.CampaignReports.Filtermain button {
  background-color: #fff;
}
.Campaigndate {
  float: left;
  width: 50%;
  margin-bottom: 20px;
}

input.to_date {
  border: 1px solid #b1b1b1 !important;
  padding: 7px;
  margin-right: 5px;
  color: #666;
  width: 90%;
}
input.from_date {
  border: 1px solid #b1b1b1 !important;
  padding: 7px;
  margin-right: 5px;
  color: #666;
  width: 100%;
}
.ModalStyleBtns.CampaignBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.Filtermain.audienceFiltermain {
  position: absolute;
  left: 45%;
  top: -5px;
}
.searchnFlowBtn.audiencesearchnFlowBtn .uk-search-input {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  margin-right: 25px;
  float: left;
  width: 450px;
  color: #8e8e8e;
  font-size: 14px;
  line-height: 18px;
  padding: 8px 15px 5px 15px;
}

.messages_box.CampaignChatmessages_box {
  height: calc(100vh - 260px);
  padding-top: 10px;
}
.Filtermain.automaationFiltermain {
  position: absolute;
  right: 35px;
  top: -5px;
}
.Filtermain.automaationFiltermainkeyword {
  position: absolute;
  right: 35px;
  top: -5px;
}
.MainFormsection {
  width: 90%;
  padding: 20px;
}
.MainFormsection .label {
  vertical-align: middle;
  display: flex;
  align-items: center;
}
.Filtermain.automaationFiltermain button {
  background-color: #fff;
}
.Filtermain.automaationFiltermainkeyword button {
  background-color: #fff;
}
.MainFormsection select,
.MainFormsection textarea {
  border-radius: 6px;
}
.MainFormsection .uk-select:not([multiple]):not([size]) {
  background-image: url(/public/images/arrow-down.svg);
  background-size: 25px;
}
.dayFormBtn {
  display: flex;
  justify-content: center;
}
.Filtermain.audienceSegFiltermain {
  position: absolute;
  right: 35px;
  top: -5px;
}
.Filtermain.audienceContactFiltermain button {
  background-color: #fff;
}
.Filtermain.audienceContactFiltermain {
  position: absolute;
  right: 546px;
  top: -6px;
}
.Filtermain.audienceSegFiltermain button {
  background: #fff;
}
select.to_date {
  border: 1px solid #b1b1b1 !important;
  padding: 7px;
  margin-right: 5px;
  color: #666;
  width: 90%;
}
select.from_date {
  border: 1px solid #b1b1b1 !important;
  padding: 7px;
  margin-right: 5px;
  color: #666;
  width: 100%;
}

/* packages start */
.cards.packages h2.uk-card-title {
  font-size: 28px;
  line-height: 35px;
  margin-bottom: 10px;
}

.cards.packages h6 {
  color: #000000;
  margin: 0;
  font-size: 14px;
  line-height: 17px;
}
.actions.prices {
  display: block;
  margin-top: 20px;
}
.actions.prices small {
  font-size: 12px;
  color: #000000;
}
.actions.prices h1 {
  font-size: 60px;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}
ul.actions.prices.bottom {
  padding: 0;
  margin-top: 0px;
}
ul.actions.prices.bottom li {
  list-style: none;
}
ul.actions.prices.bottom li h5 {
  margin: 0;
  color: #000000;
  font-size: 16px;
  font-weight: bold;
}
ul.actions.prices.bottom p {
  margin-top: 0;
  margin-bottom: 16px;
  font-size: 13px;
  color: #8e8e8e;
  margin-bottom: 10px;
}

.cards.packages .uk-card {
  border: 1px solid #e6e6e6;
  border-radius: 4px;
}

.ModalStyleBtns.priceAction .AddTeamBtn {
  font-size: 12px;
  margin-right: 10px;
}
.ModalStyleBtns.priceAction .CnclBtn {
  font-size: 12px;
  margin-right: 0px;
}
.ModalStyleBtns.priceAction {
  display: flex;
  justify-content: flex-end;
}

/* packages end */
.teamTabFiltermain {
  position: absolute;
  left: 66.7%;
  top: -5px;
}

.teamAgentFiltermain {
  position: absolute;
  right: 30%;
  top: -5px;
}
.Filtermain.teamTabFiltermain button {
  background-color: #fff;
}
.Filtermain.teamAgentFiltermain button {
  background-color: #ffff;
}
button.AddTeamBtn.disabled {
  background-color: #eee;
  border: 1px solid #eee;
  color: #000;
  cursor: context-menu;
}

button.CnclBtn.disabled {
  background-color: #eee;
  border: 1px solid #eee;
  color: #000;
  cursor: context-menu;
}

table.uk-table.uk-table-small.uk-table-divider.packagesTable {
  border: 1px solid #e5e5e5;
}
table.packagesTable tr td:first-child {
  border-right: 1px solid #e5e5e5;
}
.TopBar {
  background-color: red;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  width: calc(100% + 60px);
  margin-left: -35px;
  padding: 15px 25px 10px 35px;
  /* margin-bottom: 30px; */
}
.TopBar p {
  margin: 0;
  font-size: 14px;
  color: white;
  font-weight: 500;
}
ul.nav-links.uk-float-right li {
  padding: 10px 0px 0px 10px;
  color: #fff;
  font-size: 12px;
}
.NotificationToggle {
  justify-content: center;
  align-items: center;
  display: flex;
  float: right;
  margin-top: 10px;
}
ul.nav-links.uk-float-right {
  display: block;
}
.drpIconBox .uk-dropdown ul li button {
  display: block;
  padding: 6px 12px;
  line-height: 20px;
  font-size: 14px;
  color: #4b4b4b;
  text-transform: none;
  background-color: white;
  border: none;
  width: 100%;
  text-align: left;
}
.drpIconBox .uk-dropdown ul li:hover button {
  background-color: #f5f5f5;
}

.pause_automation.not {
  height: auto;
}
.notes {
  min-height: 210px;
  overflow-y: auto;
  height: calc(100vh - 720px);
}
.notes.showNotes {
  height: 210px;
}
.notes::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.tableSec .uk-overflow-auto::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.messages_box.msgExpired {
  height: calc(100vh - 230px);
}
.messages_box {
  /* height: calc(100vh - 350px); */
}
.notes::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
.tableSec .uk-overflow-auto::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
  height: 10px;
}
.notes::-webkit-scrollbar-thumb {
  background-color: #5f5f5f;
}

.tableSec .uk-overflow-auto::-webkit-scrollbar-thumb {
  background-color: #5f5f5f;
}
.notes button {
  background-color: #fcf7e9;
  border-radius: 7px;
  border: none;
  display: block;
  width: 100%;
  padding: 8px 12px;
  text-align: left;
  margin-bottom: 8px;
  font-family: "Adobe Clean";
}
.notes button p {
  /* margin-bottom: 8px; */
}
.notes button:hover {
  background-color: #fdfdc9;
}
.notes button .title {
  width: 100%;
  color: #000000;
  font-size: 14px;
  margin-bottom: 6px;
  font-family: "Adobe Clean";
}
.notes button .date {
  font-size: 12px;
  color: #8e8e8e;
}
.showall {
  display: inline-block;
  width: 100%;
}
.showall button {
  background-color: transparent;
  color: #007ad0;
  font-size: 14px;
  border: none;
}
.blue {
  color: #007ad0 !important;
}

.placeholder {
  min-height: 125px;
}
.contacts_list {
  display: inline-block;
  width: 100%;
  overflow-y: scroll;
  /* height: 780px; */
}
.messages_box {
  /* height: calc(100vh - 350px); */
}
.messages_box.msgExpired {
  height: calc(100vh - 230px);
}
.tableSec .CustomTableWidth {
  /* width: 1685px;
  height: 100%; */
}
/* div#ContactProfile .profile_pic {
 width: 220px;
 height: 160px;
} */

.mobileMenuIcon {
  display: none;
}
.sidebar.desktop {
  display: block;
}

.sidebar.mobile {
  display: none;
}
.mobiledateFilter {
  display: none;
}
.desktopdateFilter {
  display: block;
}
.DateFilterIcon {
  display: none;
}
/* ul.nav-links.uk-float-right li a {
  font-size: 12px;
} */

div#threadList ul {
  list-style: none;
}
.contacts_list_loader tr {
  display: flex;
  justify-content: center;
}
form#chatform {
  margin-top: -10px;
}
form#chatform label.lineht {
  line-height: 40px;
}
form#chatform input#fromdate {
  margin-bottom: 15px;
}
.profile_details {
  background-color: #fff;
}
.mobTabsBtn {
  display: none;
}
.tabsBtns.desktop {
  display: block;
}

#NewBuilder.AddTeamModal.NewBuilder .uk-modal-dialog {
  width: 320px;
  padding: 40px 20px 15px;
  background-image: url(/public/images/mobilelarge_2x.png);
  background-size: 100% 100%;
  background-repeat: no-repeat;
  border-radius: 55px;
}
form.conversation-compose {
  border-bottom-left-radius: 25px;
  border-top-left-radius: 25px;
  /* margin-right: 20px; */
  margin-left: 5px;
}

table.uk-table.uk-table-small.uk-table-divider.CustomTableWidth {
  /* width: 1550px; */
}
.ModalStyle select,
.ModalStyle input {
  border-color: #b1b1b1;
  border-radius: 4px;
  font-size: 14px;
}
.whatsApptopHeader .chat_tab .pp {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-color: black;
  border-radius: 100%;
}

aside.emoji-picker-react {
  background: #fff;
  display: flex;
  flex-direction: column;
  height: 310px;
  width: 280px;
  font-family: sans-serif;
  border: 1px solid #efefef;
  border-radius: 5px;
  box-sizing: border-box;
  box-shadow: 0 5px 10px #efefef;
  overflow: hidden;
  position: absolute !important;
  left: 0px;
  bottom: 130px !important;
}

.emojiCross svg {
  color: #007ad0;
  width: 30px;
}
/* whatsapp start */

.WhatsappBuilderBtn {
  position: absolute;
  bottom: 15px;
  right: 20px;
}

.page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.marvel-device.nexus5 {
}

.marvel-device .screen {
  text-align: left;
}

.screen-container {
  height: 100%;
}

/* Status Bar */

.status-bar {
  height: 25px;
  background: #f9f9f9f0;
  color: #fff;
  font-size: 14px;
  padding: 0 8px;
  width: 95%;
  border-radius: 25px;
  margin: 0px auto;
}

.status-bar:after {
  content: "";
  display: table;
  clear: both;
}

.status-bar div {
  float: right;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 0 0 8px;
  font-weight: 600;
}

/* Chat */

#NewBuilder .chat {
  height: calc(100% - 69px);
}

#NewBuilder .chat-container {
  height: 100%;
}

/* User Bar */

#NewBuilder .user-bar {
  height: 55px;
  background: #f9f9f9f0;
  color: #fff;
  padding: 0 8px;
  font-size: 24px;
  position: relative;
  z-index: 1;
}

#NewBuilder .user-bar:after {
  content: "";
  display: table;
  clear: both;
}

#NewBuilder .user-bar div {
  float: left;
  transform: translateY(-50%);
  position: relative;
  top: 50%;
}

.user-bar .actions {
  float: right;
  margin: 5px 0 0 10px;
}

.user-bar .actions img {
  height: 28px;
}
.user-bar .actions.more {
  margin: 0 12px 0 20px;
}

.user-bar .actions.attachment {
  margin: 0 0 0 20px;
}

.user-bar .actions.attachment i {
  display: block;
  /*   transform: rotate(-45deg); */
}

.user-bar .avatar {
  margin: 0 0 0 5px;
  width: 36px;
  height: 36px;
}

.user-bar .avatar img {
  border-radius: 50%;
  box-shadow: 0 1px 0 rgba(255, 255, 255, 0.1);
  display: block;
  width: 100%;
}

.user-bar .name {
  font-size: 17px;
  font-weight: 600;
  text-overflow: ellipsis;
  letter-spacing: 0.3px;
  margin: 0 0 0 8px;
  overflow: hidden;
  white-space: nowrap;
  width: 135px;
  color: #000000;
}

.user-bar .status {
  display: block;
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0;
}

.user-bar .back svg {
  color: #007aff;
  width: 10px;
  margin-right: 10px;
}
/* Conversation */

.sendStatusday {
  background-color: #fff;
  margin-top: 10px;
  width: 80px;
  margin: 10px auto;
  border-radius: 50px;
  font-size: 12px;
}
.sendStatusday p {
  padding-bottom: 2px;
  margin: 0;
}

.warninginfo {
  background-color: #fef4c1;
  border-radius: 6px;
}
.warninginfo span {
  width: 10px;
  float: left;
  margin: 7px 2px 0px 5px;
}
.warninginfo p {
  font-size: 12px;
  padding: 7px;
  text-align: left;
  color: #000000;
}

/* .ChatConversion {
  display: block;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 70vh;
} */
#NewBuilder .conversation {
  height: calc(100% - 12px);
  position: relative;
  background: url("https://i.ibb.co/3s1f9Jq/default-wallpaper.png") repeat;
  z-index: 0;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
  padding: 0px 0px 5px;
}

#NewBuilder .conversation ::-webkit-scrollbar {
  transition: all 0.5s;
  width: 5px;
  height: 1px;
  z-index: 10;
}

#NewBuilder .conversation ::-webkit-scrollbar-track {
  background: transparent;
}

#NewBuilder .conversation ::-webkit-scrollbar-thumb {
  background: #b3ada7;
}

#NewBuilder .conversation .conversation-container {
  height: calc(100% - 68px);
  box-shadow: inset 0 8px 10px -10px #000000;
  overflow-x: hidden;
  padding: 0 16px;
  margin-bottom: 19px;
  overflow-y: scroll;
  height: 60vh;
}

#NewBuilder button.uk-modal-close-default svg {
  background-color: #fff;
  border-radius: 50px;
}

#NewBuilder button.uk-modal-close-default {
  position: absolute;
  right: -20px;
}
#NewBuilder .conversation .conversation-container:after {
  content: "";
  display: table;
  clear: both;
}

/* Messages */

#NewBuilder .message {
  color: #000;
  clear: both;
  line-height: 18px;
  font-size: 15px;
  padding: 8px;
  position: relative;
  margin: 8px 0;
  max-width: 85%;
  word-wrap: break-word;
  z-index: -1;
}

#NewBuilder .message:after {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
}

#NewBuilder .metadata {
  display: inline-block;
  float: right;
  padding: 0 0 0 7px;
  position: relative;
  bottom: -10px;
}

#NewBuilder .metadata .time {
  color: rgba(0, 0, 0, 0.45);
  font-size: 11px;
  display: inline-block;
}

#NewBuilder .metadata .tick {
  display: inline-block;
  margin-left: 2px;
  position: relative;
  top: 4px;
  height: 16px;
  width: 16px;
}

#NewBuilder .metadata .tick svg {
  position: absolute;
  transition: 0.5s ease-in-out;
}

#NewBuilder .metadata .tick svg:first-child {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(180deg);
  transform: perspective(800px) rotateY(180deg);
}

#NewBuilder .metadata .tick svg:last-child {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: perspective(800px) rotateY(0deg);
  transform: perspective(800px) rotateY(0deg);
}

#NewBuilder .metadata .tick-animation svg:first-child {
  -webkit-transform: perspective(800px) rotateY(0);
  transform: perspective(800px) rotateY(0);
}

#NewBuilder .metadata .tick-animation svg:last-child {
  -webkit-transform: perspective(800px) rotateY(-179.9deg);
  transform: perspective(800px) rotateY(-179.9deg);
}

#NewBuilder .message:first-child {
  margin: 16px 0 8px;
}

#NewBuilder .message.received {
  background: #fff;
  border-radius: 0px 5px 5px 5px;
  float: left;
  position: relative;
  z-index: 10;
}

#NewBuilder .message.received .metadata {
  padding: 0 0 0 16px;
}

#NewBuilder .message.received:after {
  border-width: 0px 10px 10px 0;
  border-color: transparent #fff transparent transparent;
  top: 0;
  left: -10px;
}

#NewBuilder .message.sent {
  background: #e1ffc7;
  border-radius: 5px 0px 5px 5px;
  float: right;
}

#NewBuilder .message.sent:after {
  border-width: 0px 0 10px 10px;
  border-color: transparent transparent transparent #e1ffc7;
  top: 0;
  right: -10px;
}

/* Compose */

#NewBuilder .conversation-compose {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  overflow: hidden;
  height: 40px;
  width: 100%;
  z-index: 2;
}

#NewBuilder .conversation-compose div,
#NewBuilder .conversation-compose input {
  background: #fff;
  height: 100%;
}

#NewBuilder .conversation-compose .emoji {
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 50% 0 0 50%;
  flex: 0 0 auto;
  margin-left: 8px;
  width: 38px;
  height: 38px;
}

#NewBuilder .conversation-compose .input-msg {
  border: 0;
  flex: 1 1 auto;
  font-size: 14px;
  margin: 0;
  outline: none;
  min-width: 50px;
  height: 36px;
  padding-left: 15px;
}

#NewBuilder .conversation-compose .photo {
  flex: 0 0 auto;
  border-radius: 0 30px 30px 0;
  text-align: center;
  width: auto;
  display: flex;
  padding-left: 6px;
  height: 36px;
}

#NewBuilder .conversation-compose .photo img {
  display: block;
  color: #7d8488;
  font-size: 24px;
  transform: translate(-50%, -50%);
  position: relative;
  top: 50%;
  margin-left: 10px;
}

#NewBuilder .conversation-compose .send {
  background: transparent;
  border: 0;
  cursor: pointer;
  flex: 0 0 auto;
  margin-right: 8px;
  padding: 0;
  position: relative;
  outline: none;
  margin-left: 0.5rem;
}

#NewBuilder .conversation-compose .send .circle {
  background: #008a7c;
  border-radius: 50%;
  color: #fff;
  position: relative;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#NewBuilder .conversation-compose .send .circle i {
  font-size: 24px;
  margin-left: 1px;
}

#NewBuilder .status-bar img {
  width: 100%;
}
.disabled-link {
  pointer-events: none;
}
div#quickReplyMsg ul li button {
  border-radius: 14px;
  border: 1px solid #007ad0;
  background-color: white;
  font-size: 14px;
  color: #000000;
  padding: 5px 10px;
  cursor: pointer;
  text-align: left;
  margin-right: 15px;
}
div#QuickReply .uk-modal-dialog {
  width: 590px;
}
.user_selection .uk-dropdown.uk-open {
  display: block !important;
}
.profile_action .uk-dropdown.uk-open {
  display: block !important;
}
.action_btns button.disabledBtn {
  color: white;
  background-color: #999999;
  border-color: #999999;
}

.newMsg {
  border-left: 3px solid #0a84ff;
  background-color: #eeeeee6b;
}
.locationbtn a {
  background-color: #fff;
  color: #000 !important;
  padding: 5px 10px 3px;
  border-radius: 4px;
}
.svgcolor svg {
  color: red;
  width: 20px;
}
#location .uk-modal-dialog {
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  width: 850px;
}
.newMsg a p {
  font-weight: 900 !important;
}
.newMsg .contacts_list .chat_tab p:first-child {
  color: #000000;
  font-size: 14px;
  font-weight: 900 !important;
}
.newMsg .chat_tab a p {
  font-weight: 900 !important;
}
.newMsg .agentList p {
  font-weight: 900 !important;
}
/* ios end */
/* Small Screens */

@media (max-width: 768px) {
  .marvel-device.nexus5 {
    border-radius: 0;
    flex: none;
    padding: 0;
    max-width: none;
    overflow: hidden;
    height: 100%;
    width: 100%;
  }

  .marvel-device > .screen .chat {
    visibility: visible;
  }

  .marvel-device {
    visibility: hidden;
  }

  .marvel-device .status-bar {
    display: none;
  }

  .screen-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .conversation {
    height: calc(100vh - 55px);
  }
  .conversation .conversation-container {
    height: calc(100vh - 120px);
  }
  .moblogo {
    display: inline-block;
  }
}

.Sidebar_dropdown.selectBox select {
  width: 160px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  padding: 5px 30px 5px 10px;
  margin-left: 8px;
}

.tableSec .uk-pagination > .uk-active > * {
  color: #037de1;
}

.numberSorting.uk-float-right {
  padding-right: 40px;
  padding-top: 20px;
}
.numberSorting.uk-float-right button {
  padding-top: 10px;
  border-radius: 40px;
  border: 1px solid #007ad0;
  padding-right: 5px;
  padding-left: 10px;
  line-height: 10px;
  padding-bottom: 7px;
}

.numberSorting .uk-dropdown {
  position: absolute;
  z-index: 1020;
  --uk-position-offset: 10px;
  --uk-position-viewport-offset: 15px;
  box-sizing: border-box;
  min-width: 170px;
}
.numberSorting.uk-float-right .uk-dropdown {
  margin-left: 10px !important;
  left: 0px !important;
  padding: 0px;
}

.numberSorting svg {
  margin-top: -3px;
}
.tableSec ul.uk-pagination.uk-float-right {
  padding-right: 45px;
}

.tableSec .uk-pagination > * > * {
  display: block;
  padding: 5px 18px;
  color: #999;
  transition: color 0.1s ease-in-out;
  font-size: 15px;
}
.numberSorting ul.uk-nav.uk-dropdown-nav li {
  border-bottom: 1px solid #eee;
}

.numberSorting ul.uk-nav.uk-dropdown-nav li a {
  padding: 10px;
}

.Sidebar_dropdown.selectBox select {
  width: 160px;
  height: 35px;
  border-radius: 5px;
  border: 1px solid #e4e4e4;
  padding: 5px 30px 5px 10px;
  margin-left: 8px;
}
.selctWhatsImg {
  width: 30px;
  float: left;
  display: block;
  position: relative;
}
.selctWhatsImg svg {
  position: absolute;
  top: 3px;
  left: 2px;
}
.selctWhatsImg svg {
  width: 100%;
  margin-top: 0px;
}
.login_side2.h_match.desktop.BgImg {
  display: flex;
}

.login_side1_inner.mobile svg {
  display: none;
}
div#suggested_msgs ul {
  position: relative;
  z-index: 2;
}
.whatsAppDownload svg path {
  fill: #9b9a9a !important;
}

.AudienceAddTagsModal {
  overflow: hidden;
}
.whatsappProfilelogo {
  position: absolute;
  top: 20px;
  right: 0;
}

.whatsappProfilelogo svg {
  border-radius: 50%;
}
div#NotificationDropDown {
  padding: 0;
}
div#NotificationDropDown .action_menu li {
  display: 100%;
  width: 100%;
  display: block;
  float: left;
}
div#NotificationDropDown ul li a span {
  color: #000;
}
div#NotificationDropDown ul {
  padding: 0px;
}
button#notification svg {
  color: #fff;
  width: 25px;
}

.Notificationbutton svg circle div#NotificationDropDown {
  min-width: 350px;
  top: 45px !important;
  left: -320px !important;
}

.ModalStyleBtns.ClearFiltersBtn {
  width: 50%;
  float: left;
}
.ModalStyleBtns.CampaignBtn.ClearBtns {
  width: 50%;
  margin: 0px;
}
.profile_pic.audience_pic img {
  width: 160px;
}

.customer .whatsAppDownload {
  display: flex;
  justify-content: end;
}
.numberSorting select {
  align-items: 20px;
  margin: 0px 5px;
}

.NotificationToggleNew {
  display: flex;
  align-items: center;
  align-content: center;
  vertical-align: middle;
}
.NotificationToggleNew label.CustomNoti {
  /* margin-right: 20px; */
  margin-left: 14px;
  margin-right: 14px;
}
.NotificationToggleNewBot {
  display: flex;
  align-items: center;
  align-content: center;
  vertical-align: middle;
  height: 6vh;
}
a.slider.round {
  margin: 0;
}
.tableSec .uk-overflow-auto {
  min-height: 75vh;
}
/* .Filtermain.automaationFiltermain button.popadvanceFilter {
  position: relative;
  right: -29px;
}
.Filtermain.automaationFiltermainkeyword button.popadvanceFilter {
  position: relative;
  right: -30px;
}

.Filtermain.audienceContactFiltermain button.popadvanceFilter {
  position: relative;
  right: -23px;
}

.Filtermain.audienceSegFiltermain button.popadvanceFilter {
  position: relative;
  right: -20px;
}

.Filtermain.teamAgentFiltermain button.popadvanceFilter {
  position: fixed;
  right: 14.5%;
} */

div#notificationList {
  min-width: 450px;
  padding-bottom: 0px;
}

.notification_profile_pic {
  width: 50px;
  float: left;
}
.notification_profile_text {
  float: left;
  padding: 10px;
}
div#notificationList ul {
  padding: 0px;
}
div#notificationList ul li {
  border-bottom: 1px solid #eee;
  width: 100%;
  padding: 0 10px;
}
.notification_profile_text {
  float: left;
  padding: 10px;
  width: 100%;
}
.notification_profile_text .title {
  margin: 0;
  font-size: 16px;
  font-weight: bold;
  width: 100%;
}
.notification_profile_text .content {
  width: 100%;
  color: #000;
  font-size: 14px;
  display: block;
}
.notification_profile_text .DateTime {
  width: 100%;
  display: block;
  color: #000;
  font-size: 10px;
}

.screen-container .DateTimer {
  position: relative;
  width: 95%;
  margin: 0px auto;
  padding: 0 8px;
}
.screen-container .DateTimer span {
  background-color: #f9f9f9;
  position: absolute;
  top: -20px;
  color: #000;
  font-size: 12px;
  font-family: "Adobe Clean";
  font-weight: bold;
}

.tagInput {
  width: 70%;
  float: l;
  float: left;
}
.tagInputLabel {
  width: 130px;
  float: left;
}

li.hintAlert a svg {
  margin-top: -4px;
}

aside.emoji-picker-react {
  z-index: 2;
}
.WhatsappBuilderBtn {
  display: block;
}
/* whatsapp end */
.topbarFixed {
  float: left;
  width: calc(100% + 60px);
  background: #fff;
  margin-left: -35px;
  padding: 15px 25px 10px 35px;
  margin-bottom: 30px;
}
.tabsStyle {
  float: left;
  margin: 0;
  padding: 0;
  list-style: none;
}
.tabsStyle li {
  float: left;
  margin-right: 30px;
}
.tabsStyle li a {
  float: left;
  padding: 10px 0;
  color: #8e8e8e;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  position: relative;
}
.tabsStyle li a:hover,
.tabsStyle li.active a {
  color: #0a84ff;
}
.tabsStyle li a::before {
  position: absolute;
  left: 0;
  bottom: -10px;
  background: #0a84ff;
  content: "";
  width: 100%;
  height: 2px;
  display: none;
}
.tabsStyle li.active a:before {
  display: block;
}
.topbarFixed form#chatform {
  margin-top: 0;
}
.topbarFixed form#chatform input#fromdate {
  margin-bottom: 0px;
}
.agenList {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  display: inline-block;
  width: 100%;
  margin-bottom: 30px;
  padding: 15px;
}
.agenList ul {
  float: left;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}
.agenList ul li {
  float: left;
  margin-right: 30px;
}
.agenList ul li:last-child {
  float: right;
  margin-right: 0;
}
.agenList ul li a {
  float: left;
  color: #4b4b4b;
  line-height: 20px;
  font-size: 14px;
}
.agenList ul li a svg {
  float: left;
  margin-right: 10px;
}
.agenList ul li a p {
  float: left;
  margin: 0;
}
.agenList ul li a p span {
  color: #0283f1;
}
.page_body_content.chatSec {
  padding: 0px 0px 0px 70px;
}
.chat_container .search_field {
  max-width: 395px;
  display: inline-block;
  width: 100%;
}
.chat_container .NotificationToggleNew {
  display: inline-block;
}
.page_body_content.RemovePadding {
  padding: 0px 0px 0px 70px;
  background: #fff;
}
.iconcircleBtn {
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  border-radius: 100%;
  background: #f5f5f5;
  color: #4b4b4b;
  border: none;
}
.iconcircleBtn:hover {
  background: #007ff9;
  color: #fff;
}
.danger.iconcircleBtn:hover {
  background: #c9252d;
}
.success.iconcircleBtn:hover {
  background: #32d74b;
  color: #fff;
}
.contactContainer .head {
  padding: 20px 25px 20px 25px;
  border-bottom: solid 3px #f5f5f5;
}
.timeExpireBox {
  display: inline-block;
  padding: 6px;
  width: 94px;
  border: solid 1px #ec2d37;
  border-radius: 4px;
  background: #fff3f4;
  color: #ec2d37;
}
.timeExpireBox svg {
  float: left;
  width: 16px;
}
.timeExpireBox div {
  float: left;
  padding-left: 5px;
  font-size: 10px;
  line-height: 16px;
}
.IntegrationsSidebar {
  float: left;
  width: 216px;
  padding: 42px 15px;
  min-height: calc(100vh - 78px);
}
.IntegrationsSidebar h5 {
  padding: 0 15px;
  color: #4b4b4b;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 20px;
}
.IntegrationsSidebar ul {
  float: left;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
}
.IntegrationsSidebar ul li {
  float: left;
  width: 100%;
}
.IntegrationsSidebar ul li a {
  float: left;
  width: 100%;
  padding: 5px 15px;
  font-size: 12px;
  line-height: 20px;
  color: #4b4b4b;
}
.IntegrationsSidebar ul li:hover a,
.IntegrationsSidebar ul li.active a {
  color: #007ff9;
}
.IntegrationsSidebar ul li a img {
  float: left;
  width: 18px;
  margin-right: 5px;
  border-radius: 4px;
}
.AccountCampain {
  display: inline-block;
  width: 100%;
  background: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  padding: 16px;
}
.connected .AccountCampain .status {
  display: inline-block;
  background-color: #32d74b1a;
  border: 1px solid #32d74b;
  border-radius: 12px;
  font-size: 12px;
  padding: 3px 10px 2px 10px;
  color: #32d74b;
}
.AccountCampain .uk-table {
  margin: 0;
}
button.disconnect.danger {
  color: #c9252d;
  text-decoration: none;
}
button.disconnect.success {
  color: #32d74b;
}
.Fimg {
  max-width: 60px;
  border-radius: 10px;
}
.bgWhite {
  background: #fff;
}
.addnum_box .fields.fieldsGrey input {
  background: #fafafa !important;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}
.addnum_box .fields.fieldsGrey label {
  color: #222222;
  font-size: 14px;
  font-weight: bold;
  font-family: "Adobe Clean";
}
.addnum_boxLarge {
  max-width: 500px;
}
.Sidebar_dropdown {
  display: block;
  width: 100%;
  padding: 8px 10px 7px 10px;
}
/* .Sidebar_dropdown button {
  width: 100%;
  background-color: #fff;
  border: 1px solid #E4E4E4;
  text-align: left;
  padding: 12px 10px 10px 10px;
  border-radius: 4px;
} */
.Sidebar_dropdown .uk-dropdown {
  padding-left: 0;
  padding-right: 0px;
  padding-top: 5px;
  padding-bottom: 10px;
  left: 10px !important;
}
.sidebar ul {
  list-style: none;
  padding-left: 0;
}
.sidebar .CustomDrop ul {
  padding-bottom: 0;
}
.CustomDrop ul li {
  overflow: hidden;
  width: 100%;
}
.Sidebar_dropdown .uk-dropdown.uk-open ul li button {
  border: none;
  padding: 5px 10px;
}
.CustomDrop button img {
  width: 20px;
  margin-right: 30px;
}
.sidebar:hover .CustomDrop button img {
  margin-right: 10px;
}
span.DropNumber {
  margin-top: 0px;
  display: inline-block;
  line-height: 20px;
  margin-left: 10px;
  padding-top: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 127px;
  vertical-align: text-top;
}
.Sidebar_dropdown .CustomDrop button .uk-icon {
  float: right;
}
.disconectAcount img.uk-border-circle {
  max-width: 60px;
}
.CustomBgAdmin.removePad {
  padding: 0;
  float: none;
  display: inline-block;
}
.CustomBgAdmin.removePad .integration.inner {
  padding: 20px;
  background: #fff;
}
.integration.inner.fullWdth {
  width: 100%;
  float: none;
  display: inline-block;
}
.SidebarNotification .uk-offcanvas-bar {
  width: 300px;
  background: #fafafa;
  color: #000000;
  padding: 20px 0;
}
.SidebarNotification .uk-offcanvas-close,
.SidebarNotification .uk-offcanvas-close:hover {
  color: #000;
}
.SidebarNotification h3 {
  font-size: 20px;
  padding: 10px 15px 0;
  margin: 0 0 20px;
  color: #000;
  font-weight: bold;
}
.SidebarNotification h6 {
  font-size: 14px;
  padding: 0 15px;
  margin: 0 0 20px;
  color: #000;
  font-weight: bold;
}
.SidebarNotification ul {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  max-height: calc(100vh - 145px);
  overflow: hidden;
  overflow-y: auto;
}
.SidebarNotification ul::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
.SidebarNotification ul::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
}
.SidebarNotification ul::-webkit-scrollbar-thumb {
  background-color: #5f5f5f;
}
.SidebarNotification li {
  float: left;
  width: 100%;
}
.SidebarNotification li:hover .notificationBox {
  background: #fff;
}
.notificationBox {
  float: left;
  width: 100%;
  padding: 15px;
  position: relative;
}
.notificationBox::before {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  background: #0283f1;
  border: solid 1px #0283f1;
  width: 8px;
  height: 8px;
  content: "";
  border-radius: 100%;
}
.SidebarNotification li:hover .notificationBox::before {
  background: #fff;
}
.notificationImg {
  float: left;
  position: relative;
  width: 40px;
}
.notificationImg .pp {
  border: solid 1px #fff;
}
.notificationImg .bp {
  position: absolute;
  width: 15px;
  border: solid 1px #fff;
  right: 0;
  bottom: 0;
}
.notificationTxt {
  float: left;
  width: calc(100% - 40px);
  padding-left: 12px;
}
.notificationTxt p {
  float: left;
  width: 100%;
  margin: 0;
  color: #4b4b4b;
  font-size: 12px;
  line-height: 16px;
  max-height: 32px;
  overflow: hidden;
}
.notificationTxt span {
  color: #8e8e8e;
  font-size: 10px;
  line-height: 16px;
  float: left;
  width: 100%;
}
div#controls button {
  padding: 10px;
  margin-left: 10px;
  color: #fff;
  background-color: #007ad0;
  border: none;
  border-radius: 5px;
}
@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.blink {
  text-decoration: blink;
  animation-name: blinker;
  animation-duration: 0.6s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  color: red;
}
div#errorAlertAudioPlayer {
  margin-top: 15px;
}
#controls button:disabled {
  background-color: #eee;
  color: #000;
}

.ContainerCustom {
  max-width: 1200px;
}
.integBodyTxt {
  min-height: 130px;
  display: inline-block;
  width: 100%;
}
.actionStyle {
  display: inline-block;
  width: 100%;
  border-top: solid 1px #eaeaea;
  padding-top: 15px;
}
.CustomDrop ul {
  padding-bottom: 0;
}
.adminContainerinner {
  height: calc(100vh - 80px);
  float: left;
  width: 100%;
  background-color: #f5f5f5;
}
.addSpace {
  float: none;
  display: inline-block;
}
.CustomBgAdmin.addSpace .integration.inner {
  padding: 20px;
  background: #fff;
}
.tagInputLabel.widthMax {
  width: 170px;
}
.tagInput.rightW {
  float: right;
  max-width: 450px;
}
.overCardTxt {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.overCardTxt h3 {
  color: #4b4b4b;
  font-size: 36px;
  margin: 0 0 4px;
}
.overCardTxt p {
  color: #8e8e8e;
  margin: 0;
}
.overCardTxt .Delivered {
  color: #1f73b7;
}
.PaddingBottom {
  padding-bottom: 50px;
}
.MultiSelectFilter {
  float: left;
  position: relative;
}
.MultiSelectFilter p {
  float: left;
  color: #4b4b4b;
  font-size: 28px;
  line-height: 36px;
  margin: 0;
}
.MultiSelectFilter p svg {
  width: 34px;
}
.MultiSelectFilter p span {
  width: 150px;
  float: left;
}
.UltabsBtns {
  position: absolute;
  left: 0;
  top: 40px;
  margin: 0;
  padding: 5px 0;
  list-style: none;
  display: none;
  background: #ffffff;
  box-shadow: 0px 1px 4px #2d2d2d26;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  width: 180px;
  z-index: 11;
}
.UltabsBtns.showMenu {
  display: block;
}
.UltabsBtns li {
  float: left;
  width: 100%;
}
.UltabsBtns li label {
  float: left;
  width: 100%;
  padding: 7px 12px;
  color: #4b4b4b;
  font-size: 14px;
  line-height: 20px;
}
.UltabsBtns li:hover a {
  background: #f5f5f5;
}
.UltabsBtns li label img {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  margin-right: 10px;
}
.UltabsBtns li:last-child {
  margin-top: 5px;
  border-top: solid 1px #e4e4e4;
}
.UltabsBtns li label input {
  float: right;
  margin-top: 2px;
}
.CustomCalender {
  float: right;
}
.infoTool {
  margin: 0 5px;
}
.infoTool .uk-dropdown {
  min-width: 270px;
  text-align: left;
}
.infoTool a {
  color: #8e8e8e;
}
.infoTool .uk-dropdown h5 {
  font-size: 16px;
  color: #4b4b4b;
  font-weight: bold;
  margin-top: 0;
}
.infoTool .uk-dropdown h6 {
  font-size: 14px;
  color: #4b4b4b;
  font-weight: bold;
  margin-top: 0;
}
.infoTool .uk-dropdown p {
  font-size: 14px;
  color: #4b4b4b;
  margin-top: 0;
}
.uk-h2 {
  color: #4b4b4b;
}
.overCardTxt .solvedTxt {
  color: #028079;
}
.iconBara {
  width: 27px;
}
.ChannelsOverviewGraph canvas {
  height: 400px !important;
}
.ChannelsOverviewGraph {
  height: 400px;
}
.rs-anim-fade.rs-anim-in.placement-bottom-start.rs-picker-daterange-menu.rs-picker-menu {
  left: 950px !important;
}
.ChannelDropDown .MultiSelectFilter p svg {
  width: 24px;
}
.ChannelDropDown .MultiSelectFilter p {
  float: left;
  color: #1f73b7;
  font-size: 18px;
  line-height: 36px;
  margin: 0;
}
.ChannelDropDown .MultiSelectFilter p span {
  width: 100px;
  float: left;
}
.ChannelDropDown {
  margin-right: 20px;
}
.head.customheader ul {
  width: 100%;
  margin-bottom: 40px;
}
.AgentTable .uk-table-striped tbody tr {
  border: none;
}
.AgentDropDown .MultiSelectFilter p span {
  width: 55px;
  float: left;
}
.AgentDropDown .MultiSelectFilter p {
  float: left;
  color: #1f73b7;
  font-size: 18px;
  line-height: 36px;
  margin: 0;
}
.AgentDropDown {
  margin-right: 20px;
}
.AgentDropDown .MultiSelectFilter p svg {
  width: 20px;
}
.ctmBtn.happyBtn {
  background-color: #0280790d;
  border-color: #028079;
  color: #028079;
}
.ctmBtn.unhappyBtn {
  background-color: #fff7d0;
  border-color: #f79a3e;
  color: #f79a3e;
}
.chart__bubble {
  height: 100%;
  overflow: hidden;
  width: 100%;
}
.chart__bubble .bubble {
  fill: #a4a4a477;
}

.chart__bubble .leaf {
  fill: #e20074;
}
.ProgressBarChartCustom {
  width: 100%;
  position: relative;
  display: block;
}
.ProgressBarChartCustom .right {
  width: 66.7%;
  float: right;
  display: block;
  position: absolute;
  right: 0;
  top: 100px;
}
.ProgressBarChartCustom .left {
  width: 33.3%;
  float: left;
  display: block;
  position: absolute;
  top: 65px;
}
.ProgressBarChartCustom .left ul li span {
  color: #8e8e8e;
}
.ProgressBarChartCustom .left ul li {
  margin-top: 5px;
}
.ProgressBarChartCustom .left h6 {
  color: #028079;
  font-size: 16px;
  line-height: 17px;
  margin-bottom: 16px;
}
.ProgressBarChartCustom .right .uk-progress {
  vertical-align: baseline;
  width: 80%;
  border: 0;
  background-color: #f8f8f8;
  margin-bottom: 20px;
  height: 8px;
  border-radius: 500px;
  overflow: hidden;
  display: inline-flex;
  margin: 0;
}
.ProgressBarChartCustom .right ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  margin-top: 5px;
}
.ProgressBarChartCustom .right ul li span {
  font-size: 10px;
  padding: 0px 0px 0px 10px;
  color: #4b4b4b;
  margin-top: 4px;
  display: block;
  float: left;
  margin-right: 4px;
  width: 35px;
}
.ProgressBarChartCustom .right .uk-progress.unhappy::-webkit-progress-value {
  background: #96ccf9;
}
ul.labelResoltuionTime {
  position: absolute;
  top: 60px;
}
ul.labelResoltuionTime li::before {
  content: "";
  color: #1f73b7;
  width: 8px;
  height: 8px;
  background-color: #1f73b7;
  border-radius: 100%;
  border: 1px solid;
  margin-top: -3px;
  margin-right: 5px;
}
ul.labelResoltuionTime li {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
ul.labelResoltuionTime .Label {
  /* max-width: 200px; */
  width: 200px;
  float: left;
}
ul.labelResoltuionTime progress {
  margin: 0;
  width: 400px;
  height: 8px;
}
ul.labelResoltuionTime .Percent {
  /* max-width: 200px; */
  width: 50px;
  float: right;
  margin-left: 10px;
}
.labelResoltuionTimeMain {
  position: relative;
}

.PhoneInput {
  display: flex;
  align-items: center;
  border-color: #b1b1b1;
  border-radius: 4px;
}
.PhoneInput input#phon {
  border: none;
  height: 38px;
}
input#phonedit {
  border: none;
  height: 38px;
}

.custom-uk-countdown {
  display: block;
  width: 50%;
  float: left;
  margin-top: 10%;
}
.custom-uk-countdown-right .uk-list {
  border-left: 1px solid #eee;
}
.custom-uk-countdown .uk-countdown-separator {
  font-size: 20px;
  line-height: 0.6;
}
.custom-uk-countdown .uk-countdown-number {
  font-size: 36px;
  line-height: 0.6;
  float: left;
  display: block;
}
.custom-uk-countdown-right {
  width: 50%;
  float: right;
  margin: 0;
}
.custom-uk-countdown .Timer {
  width: 100%;
  float: left;
  justify-content: center;
  display: flex;
}
.custom-uk-countdown .TimerLabel {
  text-align: center;
  margin-top: 15px;
}
.custom-uk-countdown-right ul li.blue:before {
  display: inline-block;
  content: "";
  width: 6px;
  height: 6px;
  background: #1f73b7;
  border-radius: 100%;
  margin-left: 20px;
  margin-right: 10px;
  line-height: 0;
}
.custom-uk-countdown-right ul li.light-blue:before {
  display: inline-block;
  content: "";
  width: 6px;
  height: 6px;
  background: #96ccf9;
  border-radius: 100%;
  margin-left: 20px;
  margin-right: 10px;
  line-height: 0;
}
.custom-uk-countdown-right ul li.yellow:before {
  display: inline-block;
  content: "";
  width: 6px;
  height: 6px;
  background: #f6c176;
  border-radius: 100%;
  margin-left: 20px;
  margin-right: 10px;
  line-height: 0;
}
.custom-uk-countdown-right ul li.green:before {
  display: inline-block;
  content: "";
  width: 6px;
  height: 6px;
  background: #1db954;
  border-radius: 100%;
  margin-left: 20px;
  margin-right: 10px;
  line-height: 0;
}
.custom-uk-countdown-right ul li span.label {
  color: #8e8e8e;
}
.custom-uk-countdown-right ul li span.value {
  float: right;
  color: #4b4b4b;
}
.MainTabs {
  margin-top: 25%;
}
.AgentTable .primary {
  color: #028079;
}
.AgentTable .danger {
  color: #c9252d;
}
.CustomSearch {
  float: right;
}
.CustomSearch .uk-search-default {
  width: 240px;
  height: 32px;
  border: 1px solid #e4e4e4;
  color: #f5f5f5;
}
.CustomSearch .uk-search-default .uk-search-input {
  height: 32px;
  border: 1px solid #e4e4e4;
}
.BroadCastTable {
  border-spacing: 30px;
}

.BroadCastTable thead tr {
  background: transparent;
}
.BroadCastTable tr {
  background-color: #fff;
  margin-bottom: 20px;
}
.BroadCastTable tr:hover {
  box-shadow: 10px 10px 10px #eee;
}

.progress {
  border: 1px solid #c0c0c0;
  padding: 5px 10px 3px;
  border-radius: 15px;
  color: #8e8e8e;
  font-size: 12px;
  background: #f5f5f5;
}

.schedule {
  border: 1px solid #8e8e8e;
  padding: 5px 10px 3px;
  border-radius: 15px;
  color: #ffffff;
  font-size: 12px;
  background: #8e8e8e;
}

.paused {
  border: 1px solid #f79a3e;
  padding: 5px 10px 3px;
  border-radius: 15px;
  color: #f79a3e;
  font-size: 12px;
  background: #fff7d0;
}

.canceled {
  border: 1px solid #eb4962;
  padding: 5px 10px 3px;
  border-radius: 15px;
  color: #eb4962;
  font-size: 12px;
  background: #fdf1ef;
}

.complete {
  border: 1px solid #028079;
  padding: 5px 10px 3px;
  border-radius: 15px;
  color: #028079;
  font-size: 12px;
  background: #daeded;
}
.sent {
  border: 1px solid #028079;
  padding: 5px 10px 3px;
  border-radius: 15px;
  color: #ffffff;
  font-size: 12px;
  background: #028079;
}

.bounced {
  border: 1px solid #f79a3e;
  padding: 5px 10px 3px;
  border-radius: 15px;
  color: #f79a3e;
  font-size: 12px;
  background: #fff7d0;
}
.BroadCastTable td {
  vertical-align: middle;
}
span.statusLabel {
  width: 100%;
}
.dropdown-status {
  padding: 10px 20px 5px;
}
.BroadCastTable .statusBtn {
  color: #8e8e8e;
}
.status-dropdown-nav li.uk-active a {
  color: #1f73b7;
}
.status-dropdown-nav li.uk-active .tick {
  display: block;
}
.tick {
  display: none;
}
ul.labelResoltuionTime.whatsappmessage .Label {
  /* max-width: 200px; */
  width: 140px;
  float: left;
}
ul.labelResoltuionTime.whatsappmessage .Percent {
  width: 80px;
  color: #4b4b4b;
}
ul.labelResoltuionTime.whatsappmessage li::before {
  margin-top: -3px;
}
.labelResoltuionTime.whatsappmessage
  .uk-progress.delivered::-webkit-progress-value {
  background: #1f73b7;
}
.labelResoltuionTime.whatsappmessage
  .uk-progress.pending::-webkit-progress-value {
  background: #96ccf9;
}
.labelResoltuionTime.whatsappmessage
  .uk-progress.expired::-webkit-progress-value {
  background: #f6c176;
}
.labelResoltuionTime.whatsappmessage
  .uk-progress.rejected::-webkit-progress-value {
  background: #c9252d;
}
.labelResoltuionTime.whatsappmessage
  .uk-progress.undeliverable::-webkit-progress-value {
  background: #b4b4b4;
}

.labelResoltuionTimeMain .heading {
  position: absolute;
  right: 0;
  top: 40px;
}
.labelResoltuionTimeMain .heading li {
  display: inline-block;
  float: right;
  padding: 0px 5px;
  margin: 0;
}
.labelResoltuionTimeMainDelivery progress {
  margin: 0;
  width: 400px;
  height: 8px;
}
.labelResoltuionTimeMainDelivery .uk-progress::-webkit-progress-value {
  background: #1f73b7;
}
.labelResoltuionTimeMainDelivery .uk-table td {
  padding: 5px 12px;
  vertical-align: middle;
}

.labelResoltuionTime.whatsappCategories {
  top: 90px;
}
.labelResoltuionTime.whatsappCategories .uk-progress::-webkit-progress-value {
  background: #1f73b7;
}
ul.BroadcasttabsBtns {
  list-style: none;
  margin-top: 35px;
  padding: 0;
}
ul.BroadcasttabsBtns li {
  display: inline-block;

  background-color: #fff;
  margin-right: 12px;
}
.BroadcasttabsBtns li a {
  color: #464646;
  font-size: 12px;
  padding: 6px 7px 3px;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
}
.broadcastcomplete {
  border: 1px solid #028079 !important;
  background: #daeded !important;
  color: #028079 !important;
}
.BroadcastSummaryList {
  padding: 0px 30px;
}
.BroadcastSummaryList h6 {
  color: #222222;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 10px;
}
.BroadcastSummaryList ul {
  padding: 0;
}
.BroadcastSummaryList ul li {
  list-style: none;
  margin-bottom: 8px;
}
.BroadcastSummaryList ul li span {
  width: 40%;
  display: inline-block;
}
.btnReport {
  border: 1px solid #1f73b7;
  background: transparent;
  color: #1f73b7;
  font-size: 14px;
  padding: 5px 15px;
  border-radius: 4px;
  margin-right: 5px;
  float: left;
}
.broadcastMainButton {
  float: right;
  position: absolute;
  right: 60px;
  top: 17px;
}
.headingBroadcastHead {
  padding-left: 60px;
}
.numberSorting.uk-float-right.broadcastPagSort {
  padding-top: 12px !important;
}
.menuDropDown span {
  margin-left: 8px !important;
  font-size: 16px !important;
  font-weight: 500 !important;
}
.menuDropDown ul {
  padding-bottom: 0px;
}
span.dropIconMenu {
  float: right;
}
.menuDropDown ul.uk-nav-sub {
  padding-left: 25px;
}
.TeamContainer.stats .head {
  padding: 20px 25px 20px 25px;
}
.agentList {
  width: 100%;
  padding: 0px 0px 0px 65px;
  display: inherit;
  margin-top: 5px !important;
}
.agentIcon {
  margin-right: 10px;
}
#LoaderModal .loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  position: relative;
}
div#LoaderModal {
  background: rgb(0 0 0 / 75%);
}
#LoaderModal span {
  color: #2196f3;
}
#LoaderModal svg circle {
  stroke-width: 1.333333px !important;
}
#LoaderModal p {
  justify-content: center;
  align-items: center;
  display: flex;
}

#LoaderModal .uk-modal-dialog {
  position: relative;
  box-sizing: border-box;
  margin: 0 auto;
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.jq-toast-single {
  width: 100%;
  padding: 10px;
  margin: 0 0 5px;
  border-radius: 4px;
  font-size: 12px;
  font-family: arial, sans-serif;
  line-height: 18px;
  position: relative;
  pointer-events: all !important;
  background-color: #fff;
  color: #000;
  border: 1px SOLID #eee;
  box-shadow: 10px 10px 10px #eee;
}
.jq-toast-single h2 {
  margin: 0px 0 5px;
}
.jq-toast-single {
  display: inline-block;
}
.jq-toast-single .notificationBox {
  float: left;
  width: 100%;
  padding: 0px;
  position: relative;
}
.notificationTxt p ~ p {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}
span.jq-toast-loader.jq-toast-loaded {
  background-color: #2196f3 !important;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes progres {
  0% {
    width: 0%;
  }
  25% {
    width: 50%;
  }
  50% {
    width: 75%;
  }
  75% {
    width: 85%;
  }
  100% {
    width: 100%;
  }
}
@media screen and (max-width: 1920px) {
}

@media screen and (max-width: 1680px) {
  .messages_box {
    /* height: calc(100vh - 400px); */
  }
  .tableSec .CustomTableWidth {
    height: 100%;
  }
  /* div#ContactProfile .profile_pic {
    width: 220px;
  } */
  div#AddTagsModal form#tagform {
    margin-top: 20px;
  }
}
@media screen and (max-width: 1640px) {
  .messages_box {
    /* height: calc(100vh - 400px); */
  }
}

@media screen and (max-width: 1600px) {
  .messages_box {
    /* height: calc(100vh - 400px); */
  }
  /* div#ContactProfile .profile_pic {
    width: 220px;
  } */
  div#AddTagsModal form#tagform {
    margin-top: 20px;
  }
  .tableSec .CustomTableWidth {
    width: 1440px;
    height: 100%;
  }
}

@media screen and (max-width: 1440px) {
  .messages_box {
    height: calc(100vh - 330px);
  }
  /* div#ContactProfile .profile_pic {
    width: 230px;
  } */
  div#AddTagsModal form#tagform {
    margin-top: 20px;
  }
  .tableSec .CustomTableWidth {
    width: 1440px;
    height: 100%;
  }
  .tableSec .ContactCustomTableWidth {
    width: 1370px;
    height: 100%;
  }
  .head.customheader a {
    margin: 0;
  }
}

@media screen and (max-width: 1400px) {
  /* div#ContactProfile .profile_pic {
    width: 230px; */
  /* } */
  div#AddTagsModal form#tagform {
    margin-top: 20px;
  }
  .tableSec .CustomTableWidth {
    width: 1550px;
    height: 100%;
  }
  .tableSec .ContactCustomTableWidth {
    width: 1550px;
    height: 100%;
  }
}

@media screen and (max-width: 1366px) {
  .messages_box {
    /* height: calc(100vh - 365px); */
  }
  .page_body_content {
  }
  .tableSec .ContactCustomTableWidth {
    width: 1400px;
    height: 100%;
  }
  /* div#ContactProfile .profile_pic {
  width: 220px;
} */
  .placeholder {
    min-height: 110px;
  }
  .suggested_msgs ul li {
    margin: 1px;
  }
  .contact_details ul {
    margin-bottom: 10px;
  }
  .main_content {
    padding: 15px 0px 0px;
    height: calc(100vh - 120px);
  }
  .contacts_list {
    height: calc(100vh - 124px);
  }
  .chat_container .head {
    padding: 10px 25px 10px 25px;
  }
  .contact_details ul li {
    padding: 2px 0px;
  }
  .tags {
    margin-top: 6px;
  }
  .action_btns button {
    font-size: 14px;
  }
  /* .contacts_list {
    height: calc(100vh - 185px);
  } */
  .tableSec .CustomTableWidth {
    width: 1440px;
    height: 100%;
  }
  .SideBarFixed {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0px;
  }
  aside.emoji-picker-react {
    bottom: 115px !important;
  }
}
@media screen and (max-width: 1280px) {
  .action_btns button {
    font-size: 13px;
  }
  .messages_box {
    height: calc(100vh - 310px);
  }
  .suggested_msgs ul li button {
    font-size: 12px;
    padding: 5px 10px;
  }
  .placeholder {
    min-height: 110px;
  }
  .tableSec .ContactCustomTableWidth {
    width: 1440px;
    height: 100%;
  }
  .tableSec .CustomTableWidth {
    width: 1500px;
    height: 100%;
  }
  .customHeading h2 {
    font-size: 16px;
    color: #4b4b4b;
    width: 85px;
  }
  .HomeCustomDrop button {
    font-size: 16px;
    padding: 0px;
  }
  ul.nav-links.uk-float-right li a {
    font-size: 12px;
    padding: 0px 0px 0px 0px;
    float: right;
  }
  ul.nav-links.uk-float-right li {
    padding: 10px 0px 0px 7px;
  }
  form#chatform {
    margin-top: -10px;
  }
  form#chatform label.lineht {
    line-height: 40px;
  }
  form#chatform input#fromdate {
    margin-bottom: 15px;
  }
  .profile_details {
    /* height: calc(100vh - 120px); */
    background-color: #fafafa;
  }
  .customNavDrop input {
    width: 90%;
  }
  .Filtermain .uk-dropdown {
    max-width: 200px;
  }
  .chat_container .search_field {
    max-width: 320px;
  }
}

@media screen and (max-width: 1200px) {
  .stats_container .box span {
    font-size: 13px;
  }
  .tableSec .ContactCustomTableWidth {
    width: 1440px;
    height: 100%;
  }
  .tableSec .CustomTableWidth {
    width: 1500px;
    height: 100%;
  }
  /* .contacts_list {
    height: calc(100vh - 200px);
  } */
  .customHeading h2 {
    font-size: 16px;
    color: #4b4b4b;
    width: 80px;
  }
  .nav-links li {
    display: inline-block;
    padding: 10px 6px 8px 10px;
  }
}

@media screen and (max-width: 768px) {
  ul.nav-links.uk-float-left {
    display: none;
  }
  ul.nav-links.uk-float-right {
    display: none;
  }
  .mobileMenuIcon {
    position: relative;
    display: block;
  }
  .mobileMenuIcon {
    width: 50%;
    position: relative;
    float: right;
  }
  a.toplogo {
    width: 50%;
    float: left;
  }
  .mobileMenuIcon svg {
    color: #fff;
  }
  .mobileMenuIcon button {
    background-color: transparent;
    border: none;
    padding: 0;
  }
  button.uk-offcanvas-close.uk-icon.uk-close svg {
    color: #000;
    /* right: 0; */
  }
  .mobileMenuIcon {
    display: block;
  }
  .page_body_content {
    width: calc(100%);
  }
  #menuIcon2.uk-offcanvas-bar.uk-offcanvas-bar-animation.uk-offcanvas-slide {
    padding: 0;
  }
  #menuIcon2 .uk-offcanvas-bar {
    background-color: transparent;
    padding: 0px;
  }
  .sidebar {
    width: 226px;
    height: calc(100vh - 63px);
    float: left;
    padding-top: 25px;
    background-color: white;
    overflow-y: scroll;
    position: fixed;
    top: 93px;
  }
  ul.top_right_action_buttons {
    display: none;
  }
  .sidebar {
    width: 226px;
    height: calc(100vh - 155px);
    float: left;
  }
  .sidebar.desktop {
    display: none;
  }
  .stats_container .box {
    margin-bottom: 10px;
  }
  .mobiledateFilter {
    display: block;
  }
  .desktopdateFilter {
    display: none;
  }
  .DateFilterIcon {
    display: block;
  }
  .sidebar.mobile.uk-offcanvas {
    width: 100%;
  }
  .uk-offcanvas-bar.uk-offcanvas-bar-animation.uk-offcanvas-slide {
    width: 100%;
  }
  .DataFormBtn {
    width: 100%;
  }
  .fromdate {
    width: 100%;
  }
  .todate {
    width: 100%;
  }
  #mobiledateFilter button.uk-offcanvas-close.uk-icon.uk-close svg {
    color: #fff;
  }
  .sidebar.mobile .SideBarFixed {
    position: unset;
  }
  .sidebar.mobile .Sidebar_inner > ul {
    padding-bottom: 0px;
  }
  .customHeading h2 {
    font-size: 16px;
    color: #4b4b4b;
    width: 80px;
    margin-top: 5px;
  }
  .mobileMenuIcon svg {
    color: #fff;
    width: 38px;
    float: right;
  }

  .mobile .sidebar {
    top: 60px;
    height: calc(100vh - 55px);
    width: 100%;
  }

  .Sidebar_dropdown.selectBox select {
    width: 100%;
  }
  /* mobile tab */
  .mobTabsBtn {
    font-size: 14px;
    height: 50px;
    border-radius: 25px;
    font-weight: 500;
    border: 1px solid #ddd;
    background-image: url(/public/images/downicon.svg);
    background-repeat: no-repeat;
    background-position: 98% 50%;
    margin: 0 0 50px;
    position: relative;
  }
  .mobFilterBtn p,
  .mobFilterBtn ul li a,
  .mobTabsBtn p,
  .mobTabsBtn ul li a {
    float: left;
    padding: 10px 50px 10px 25px;
    line-height: 30px;
    color: #666;
    width: 100%;
  }
  .mobFilterBtn ul,
  .mobTabsBtn ul {
    margin: 0;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 50px;
  }
  .mobTabsBtn ul li {
    float: left;
    width: 100%;
  }
  .mobFilterBtn ul,
  .mobTabsBtn,
  .mobTabsBtn ul {
    padding: 0;
    background-color: #f5f5f5;
    float: left;
    width: 100%;
    display: none;
    list-style: none;
  }
  .tabsBtns.showMenu {
    display: block;
  }
  .mobTabsBtn {
    display: block;
  }
  table.uk-table.uk-table-small.uk-table-divider {
    /* overflow-x: auto; */
    width: 1150px;
    /* overflow-y: auto; */
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .tabsBtns.desktop {
    display: none;
  }
  .searchnFlowBtn.mobile {
    position: absolute;
    top: 65px;
    left: 0;
    padding: 0;
    margin: 0px 20px;
  }
  .searchnFlowBtn.mobile .uk-search {
    float: none;
    width: 100%;
  }
  .searchnFlowBtn.mobile .uk-search-input {
    background: #ffffff;
    border: 1px solid #e4e4e4;
    border-radius: 4px;
    margin-right: 32px;
    float: left;
    width: 100%;
    color: #8e8e8e;
    font-size: 14px;
    line-height: 18px;
    padding: 8px 15px 4px 15px;
  }
  .searchnFlowBtn.mobile .Filtermain.automaationFiltermain {
    position: absolute;
    top: -5px;
    left: 80%;
  }
  .searchnFlowBtn.mobile .newFlowBtn {
    float: right;
  }
  .searchnFlowBtn.mobile .Filtermain.automaationFiltermainkeyword {
    position: absolute;
    left: 80%;
    top: -5px;
  }
  .searchnFlowBtn.mobile .Filtermain.audienceContactFiltermain {
    position: absolute;
    left: 80%;
  }
  .tableSec {
    margin-top: 40px;
  }
  .searchnFlowBtn.mobile .Filtermain.audienceSegFiltermain {
    position: absolute;
    left: 80%;
  }
  .tableSec .CustomTableWidth {
    width: 1500px;
  }
  .numberSorting.uk-float-right button {
    padding-top: 10px;
    border-radius: 40px;
    border: 1px solid #007ad0;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 0px;
    line-height: 20px;
    padding-bottom: 5px;
  }
  .numberSorting.uk-float-right {
    padding-right: 15px;
    padding-top: 10px;
  }
  .teamTabFiltermain {
    position: absolute;
    left: 90%;
    top: -5px;
  }
  .teamAgentFiltermain {
    position: absolute;
    left: 81%;
  }
  .SupportMain .CnclBtn {
    margin-top: 20px;
  }
  .login_side2.h_match.desktop.BgImg {
    display: none;
  }
  path#Path_103087 {
    fill: #01acfa;
  }
  .login_side1 {
    width: 100%;
  }
  .login_side1_inner.mobile svg {
    display: block;
  }
  .login_side1_inner.mobile svg {
    width: 200px;
    text-align: center;
    margin: 0px auto 15px;
  }
  .uk-width-auto.selctPhoneMobile select {
    width: 75px;
    float: left;
  }
  .uk-width-1-5\@m.phoneMobile {
    width: calc(100% - 151px);
    float: right;
    padding-left: 0;
  }
  .CustomBgAdmin {
    padding: 15px;
  }
  .CustomBgAdmin.NotificationView .integration.inner {
    height: 100%;
  }
  .MainFormsection {
    width: 100%;
    padding: 0px;
  }
  table.uk-table.uk-table-small.uk-table-divider.webhookMobileRes {
    width: 1300px;
  }
  .WhatsappBuilderBtn {
    display: none;
  }
}

.dropdown-container {
  margin: 0px 0px 10px 0px;
  border-top: 1px solid #e4e4e4;
  width: 100%;
}

.dropdown-button {
  margin: 10px 15px 2px 14px;
  padding: 10px 0px 10px 5px;
  border: 1px solid #e4e4e4;
  border-radius: 7px;
}
.dropdown-padding {
  padding: 0;
}

.select-box {
  width: 100%;
  padding: 5px 0px 5px 5px;
}

.label-form {
  padding: 0px 30px 0px 0px;
}

.addForm-btn {
  background-color: #007ad0;
  border: solid 1px #007ad0;
  border-radius: 8px;
  padding: 5px 10px;
  color: white;
}

.ticket-form-input {
  width: 100%;
  outline: none;
  border: none;
  border: 1px solid #b1b1b1;
  padding: 5px 0px 5px 5px;
}

.dynamic-form-input {
  width: 100%;
  outline: none;
  border: none;
  border: 1px solid #b1b1b1;
  padding: 5px 0px 5px 5px;
}

.add-btn {
  background-color: #4d97f3;
  color: white;
  border-radius: 8px;
  color: white !important;
  margin-left: 10px;
}

.remove-btn {
  background-color: #e62e36;
  color: white !important;
  border-radius: 8px;
  color: white;
  margin-left: 10px;
}

/* // for stats component */
.dateflow {
  padding-right: 40px;
  position: absolute;
  right: 0;
  top: 20px;
}

.tab-container {
  position: relative;
}

.tab-container .tab-content {
  height: 64px;
  background: white;
  font-size: 14px;
  color: #8e8e8e;
  padding-left: 20px;
}

.greet-message {
  font-size: 28px;
  color: #4b4b4b;
  font-weight: 300;
  margin-bottom: -10px;
}

.page-links {
  list-style-type: none;
  padding-left: 0;
}

.page-links li {
  display: inline-block;
}
.page-links li a {
  color: #0a84ff;
}

.page-links :not(:first-child) {
  padding-left: 10px;
}

.date-text {
  color: #000000;
}

/* for card  */
.card-container {
  background: white;
  border-radius: 8px;
  color: #222222;
  padding: 20px;
  height: auto;
  box-shadow: 0px 1px 2px #00000029;
}

.stats {
  font-size: 36px;
  /* color: #4b4b4b; */
  font: normal normal normal 36px/45px Adobe Clean;
  letter-spacing: 0px;
  color: #4b4b4b;
  opacity: 1;
}

.stats-description {
  font: normal normal normal 14px/17px Adobe Clean;
  letter-spacing: 0px;
  color: #8e8e8e;
  opacity: 1;
}

.line-chart-graph {
  /* height: 290px; */
  width: 100%;
}

.colored-heading {
  /* color: #028079;
  font-size: 14px;
  font-weight: bold;
  height: 34px; */
  font: normal normal bold 14px/17px Adobe Clean;
  letter-spacing: 0px;
  color: #028079;
  opacity: 1;
}

.divider {
  margin-top: -10px;
}

.date-filter-button {
  /* background: white; */
  border: none;
  color: #0a84ff;
  font-size: 14px;
  outline: none !important;
}

.date-filter-button span {
  margin: 0px 5px;
}

.date-filter-dropdown {
  width: 150px;
  padding: 10px;
  /* padding-top: -90px !important;
  margin-left: 25px !important; */
}

.date-filter-option-heading {
  color: #4b4b4b;
  margin-left: 5px;
  font-weight: bold;
}

.date-filter-dropdown input {
  margin-top: 10px;
  font-size: 5px;
}

.date-filter-dropdown label {
  margin-left: 10px;
}

.date-filter-dropdown hr {
  margin: 10px 0px 4px 0px;
}

.primary-color {
  color: #1f73b7;
}

.filter-name {
  text-transform: capitalize;
}

/* for navbar  */
.navbar {
  background-color: white;
  position: sticky;
  top: 0;
  z-index: 1;
  overflow: hidden;
  /* width: 100%; */
  padding-bottom: 0px;
  height: 64px;
}

.democlass {
  padding-top: 100px;
}

.navbar a:hover {
  text-decoration: none;
}

.nav-btn.active {
  color: #1f73b7;
  border-bottom: 2px solid #1f73b7 !important;
  outline: none;
}

.navbar ul li {
  list-style: none;
  display: inline-block;
  margin: 0 10px;
}

.main-content {
  margin-top: 50px;
  padding-bottom: 260px;
}

.semi-circle-graph {
  width: 300px;
  height: auto;
  margin: 0 auto;
  position: relative;
}

/* .centered-text{
  align-items: center;
  text-align: center;
  margin-top: 10px;
} */

.channel-notification-count {
  position: absolute;
  background: red;
  color: white;
  border-radius: 50%;
  padding: 2px;
  margin-left: -10px;
  margin-top: -5px;
  font-size: 10px;
}

.channels-icon-container {
  position: relative;
}

.new-nav .nav-btn {
  border: none;
  background-color: white;
  outline: none;
  margin-left: 10px;
  border-radius: 0;
  cursor: pointer;
  padding-bottom: 10px;
  height: 53px;
}

.new-nav {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* .new-nav-margin{
  margin-bottom: 100px;
  z-index: 1;
} */

.expiry-date-text {
  margin-top: 20px;
  padding-right: 30px;
  /* margin-right: 20px; */
}

.circle-spots {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.dark-blue {
  background-color: #366ac1;
}

.light-blue {
  background-color: #69bbfe;
}

.legend-margin {
  margin-top: 0px !important;
}

.dark-brown {
  background-color: #b05127;
}
.light-brown {
  background-color: #f3c175;
}

.section-margin {
  margin: 100px;
}

.plans-text {
  color: #929292;
}

.main {
  background: #f0f0f0 0% 0% no-repeat padding-box;
}

.date-picker-container {
  left: -600px !important;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 1px 2px #00000029;
}

.date-selection-btn {
  background-color: #4a85ce;
  margin-right: 15px;
  margin-bottom: 15px;
  color: white;
  outline: none;
  border: none;
  padding: 10px 15px;
  border-radius: 8px;
}

.date-picker-btn-container {
  width: 890px;
  border-bottom: solid 3px #eff2f7;
  background-color: white;
}

.rdrDateDisplayWrapper {
  background-color: white !important;
}
/* for progress bar  */
.uk-progress.progress-light-coral::-webkit-progress-value {
  background-color: #ee9e1e;
}
.uk-progress.progress-light-coral::-moz-progress-bar {
  background-color: #ee9e1e;
}
.uk-progress.progress-light-coral::-ms-fill {
  background-color: #ee9e1e;
}

.uk-progress.progress-coral::-webkit-progress-value {
  background-color: #c9272d;
}
.uk-progress.progress-coral::-moz-progress-bar {
  background-color: #c9272d;
}
.uk-progress.progress-coral::-ms-fill {
  background-color: #c9272d;
}

.export-btn {
  color: #0a84ff;
  border: none;
  outline: none;
  background-color: transparent;
}

.alignment-text {
  align-items: center;
}

.page-route-links {
  color: #4b4b4b;
  opacity: 1;
  font: normal normal normal 14px/17px Adobe Clean;
}

.progressbar-style {
  height: 6px;
  margin: 0 0px 20px 0px;
}

.active-plan-padding {
  padding-bottom: 40px;
}

.semicircle-chart-text {
  position: absolute;
  top: 20%;
  left: 45%;
  color: #4b4b4b;
  opacity: 1;
  font: normal normal normal 36px/45px Adobe Clean;
}

.semicircle-chart-3 {
  position: absolute;
  top: 20%;
  left: 40%;
  color: #4b4b4b;
  opacity: 1;
  font: normal normal normal 36px/45px Adobe Clean;
}

.card-heading {
  font: normal normal normal 14px/17px Adobe Clean;
  letter-spacing: 0px;
  color: #222222;
  opacity: 1;
}

.plan-stats {
  font: normal normal bold 14px/17px Adobe Clean;
  letter-spacing: 0px;
  color: #4b4b4b;
  opacity: 1;
}

.whatsapp-graph-labels {
  background: var(--system-grey-200-f5f5f5) 0% 0% no-repeat padding-box;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  padding: 10px 8px;
  font-size: 12px;
}

.red-alert {
  background: #9a0000 0% 0% no-repeat padding-box;
  font: normal normal normal 14px/12px Adobe Clean;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.blue-alert {
  background: #3973b7 0% 0% no-repeat padding-box;
  opacity: 1;
  font: normal normal normal 14px/12px Adobe Clean;
  letter-spacing: 0px;
  color: #ffffff;
}

.notify-alert-box {
  width: 400px;
  position: fixed;
  left: 50%;
  margin-left: -200px;
  top: -100%;
  padding: 20px;
  z-index: 100000;
  background: #fff;
  color: #000;
  transition: all 0.3s ease-in-out;
  border: 2px solid #eee;
  border-radius: 5px;
  box-shadow: 10px 10px 10px #eee;
}
.notify-alert-box img {
  width: 70px;
  float: left;
  margin-right: 10px;
}

.notify-alert-box .buttons {
  text-align: right;
}

.notify-alert-box .buttons button {
  background: #2196f3;
  color: #fff;
  border: 0;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 5px;
}
.form-required-field-sign {
  color: red;
}
.loader-recovery {
  justify-content: center;
  display: grid;
  place-items: center;
  height: 100vh;
}
.loader-form {
  justify-content: center;
  display: grid;
  place-items: center;
}

#conversation_input_field {
  display: none;
}
.spacing {
  margin-left: 10px;
}

.whatsapp-graph {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.semicircle-graph-loader {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formContainer {
}
.formHeader .formHeaderWrp {
  padding: 20px 25px 20px 25px;
  border-bottom: solid 3px #f5f5f5;
}
.formHeader .formHeaderWrp .formHeading h3 {
  margin: 0;
  font-size: 18px;
  color: #000000;
  font-weight: bold;
}
.formHeader .formHeaderWrp .exportBtn {
  text-align: right;
}
.formHeader .formHeaderWrp .exportBtn button {
  font-family: inherit;
  float: right;
  color: #ffffff;
  font-size: 15px;
  line-height: 18px;
  padding: 6px 15px 6px 15px;
  background-color: #007ad0;
  border: solid 1px #007ad0;
  border-radius: 8px;
}

.formHeader .formHeaderWrp .exportBtn button:hover {
  color: #007ad0;
  background-color: #cae4f7;
}

.formContainer .formwrp{
  padding-top: 30px;
  background-color: #f5f5f5;
  min-height: calc(100vh - 75px);
}
.formContainer .formwrp .formInput label{
  font-size: 12px;
  color: #8e8e8e;
}
.formContainer .formwrp .formInput input{
  border-radius: 4px;
  font-size: 14px;
  background: #fff;
  border: 1px solid #b1b1b1 !important;
}